@use 'variables';

p-progressSpinner {
  .table-row-progress-spinner {
    width: 1rem;
    height: 1rem;

    .p-progress-spinner-circle {
      animation:
        custom-progress-spinner-dash 1.5s ease-in-out infinite,
        custom-progress-spinner-color 6s ease-in-out infinite;
    }
  }

  .solid-primary-color {
    .p-progress-spinner-circle {
      stroke: variables.$color-blue-active !important;
    }
  }

  .size-m {
    width: variables.$font-size-m;
    height: variables.$font-size-m;
  }

  .size-xl {
    width: variables.$font-size-xl;
    height: variables.$font-size-xl;
  }

  .size-xxl {
    width: variables.$font-size-xxl;
    height: variables.$font-size-xxl;
  }

  @keyframes custom-progress-spinner-color {
    100%,
    0% {
      stroke: variables.$color-blue-active;
    }
  }

  @keyframes custom-progress-spinner-dash {
    0% {
      stroke-dasharray: 1, 200;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -35px;
    }
    100% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -124px;
    }
  }
}
