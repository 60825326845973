@use 'sass:color';
@use 'variables';
@use 'mixins';

.dialog-header-container {
  padding: 2rem;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;

  .dialog-header-title {
    @include mixins.font-xl;
    font-weight: 500;
    letter-spacing: -0.03em;
  }

  .dialog-header-subtitle {
    padding-top: 0.5rem;
    width: 100%;
    @include mixins.font-m;
    letter-spacing: -0.03em;
    color: variables.$color-grey-2;
    font-weight: 400;
  }

  .dialog-header-icon {
    width: 64px;
    height: 64px;
    background-color: variables.$color-turquoise;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin-right: 17px;
    fill: #ffffff;

    i {
      color: #ffffff;
      font-size: variables.$font-size-xl;
    }
  }

  .dialog-header-close-button {
    position: absolute;
    top: 2rem;
    right: 2rem;
    fill: variables.$color-grey-3;
    cursor: pointer;
  }
}

.dialog-body-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .dialog-body-details-row {
    align-items: center;
    width: 100%;
    padding: 1rem 2rem 1rem 3rem;
    line-height: 1.3rem;

    background:
      linear-gradient(180deg, rgba(222, 222, 222, 0.2) 0%, rgba(255, 255, 255, 0) 100%),
      #ffffff linear-gradient(360deg, rgba(222, 222, 222, 0.2) 0%, rgba(255, 255, 255, 0) 100%);

    &:not(:last-child) {
      border-bottom: 1px solid color.adjust(variables.$color-border, $alpha: -0.7);
    }

    .label {
      @include mixins.font-m();
      color: variables.$color-grey-1;
    }
  }
}

.dialog-footer-buttons-group {
  padding: 0.5rem 1rem;
  display: grid;
  grid-template-columns: 2fr 1fr;
}

.dialog-footer-container {
  display: flex;
  flex-direction: row;
  padding: 2rem;

  ap-footer-button {
    width: 170px;
    font-size: 11px;

    &:not(:first-child) {
      width: 125px;
      margin-left: 18px;
    }
  }
}
