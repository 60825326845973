@use 'mixins';
@use 'variables';

.p-menu.p-menu-overlay {
  @include mixins.font-s();
  padding: 0;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  border: none;
  width: fit-content;
  background-color: variables.$color-white;
  overflow: hidden;

  .p-menu-item-link:not(.p-disabled):hover .p-menuitem-icon {
    color: variables.$color-blue-active;
  }

  .p-menu-item-link:not(.p-disabled):hover .p-menuitem-text {
    color: variables.$color-blue-active;
  }

  .p-menu-item {
    &-link {
      padding: 0.75rem 1rem;
      color: variables.$color-blue-active;

      &:hover {
        background: rgba(variables.$color-blue-light, 0.1);
        font-weight: 700;
      }
    }

    &-icon {
      color: variables.$color-blue-active;
      margin-right: 0.5rem;
    }
  }
}
