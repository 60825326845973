@use 'variables';

.p-dialog {
  background-color: variables.$color-white;
  border-radius: 1.25rem;

  .p-dialog-content {
    overflow: visible;
  }
}

.p-overlay-mask {
  background-color: rgba(0, 0, 0, 0.4);
}

p-dialog {
  .p-dialog-header {
    .p-dialog-header-actions {
      margin-right: 1rem;
    }

    .p-dialog-title {
      font-size: 1.75rem;
      font-weight: 200;
      margin: 2rem 0 2rem 2rem;
    }
  }
}
