@use 'variables';
@use 'mixins';

.p-multiselect {
  @include mixins.input();
  width: 100%;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  .p-multiselect-dropdown-icon {
    color: variables.$color-grey-1;
  }

  .p-multiselect-clear-icon {
    margin: 0 0.5rem;
  }

  &:focus-within {
    @include mixins.inputFocus();
  }
}

.p-multiselect-overlay {
  border-radius: 1.25rem;
  border: none;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  padding-right: 21px;
  padding-bottom: 22px;
  background-color: variables.$color-white;

  .p-multiselect-header {
    padding: 22px 0 0 22px;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    flex-direction: column-reverse;
    align-items: start;

    p-checkbox {
      margin: 12px 0;

      .p-checkbox-box:after {
        font-size: 12px;
        line-height: 16px;
        white-space: nowrap;
        content: 'Select all/none';
        position: absolute;
        left: calc(16px + 6px);
        color: #212529;
      }
    }

    .p-multiselect-filter-container {
      width: 100%;
      display: flex;
      align-items: center;

      .p-iconfield {
        width: 100%;
      }

      .p-multiselect-filter {
        padding-right: 1.75rem;
        margin-right: -1.75rem;
      }

      .p-inputicon {
        top: calc(50% - 7px);
        color: #495057;
      }
    }
  }

  .p-multiselect-list-container {
    max-height: 350px !important;
  }

  .p-multiselect-list {
    margin: 0 13px 0 21px;

    .p-multiselect-empty-message {
      @include mixins.font-s();
      text-align: center;
      padding: 0;
    }

    &:last-child {
      border-bottom: 1px solid variables.$color-border;
    }

    .p-multiselect-option {
      padding: 12px 0;
      color: variables.$color-grey-1;
      @include mixins.font-xs();

      &:focus {
        box-shadow: none;
      }

      &:not(.p-highlight):not(.p-disabled):hover {
        background: transparent;
        font-weight: 500;
      }

      &:nth-child(n) {
        border-top: 1px solid variables.$color-border;
      }

      p-checkbox {
        margin-right: 18px !important;
      }
    }
  }

  ::-webkit-scrollbar {
    width: 6px;
    border-radius: 30px;
  }

  ::-webkit-scrollbar-track {
    border-radius: 30px;
    background: variables.$color-grey-2;
  }

  ::-webkit-scrollbar-thumb:hover,
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #fff;
    border: 1px solid variables.$color-grey-2;
  }
}
