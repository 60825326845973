.p-badge {
  background: #007bff;
  color: #ffffff;
  font-size: 0.75rem;
  font-weight: 500;
  min-width: 1.5rem;
  height: 1.5rem;
  line-height: 1.5rem;
}

.p-badge.p-badge-secondary {
  background-color: #6c757d;
  color: #ffffff;
}

.p-badge.p-badge-success {
  background-color: #28a745;
  color: #ffffff;
}

.p-badge.p-badge-info {
  background-color: #17a2b8;
  color: #ffffff;
}

.p-badge.p-badge-warn {
  background-color: #f58a1f;
  color: white;
}

.p-badge.p-badge-danger {
  background-color: #dc3545;
  color: #ffffff;
}
