@use 'variables';
@use '_button';
@use 'input/_input';
@use 'input/input-number';
@use 'input/input-text';
@use 'multiSelect/_multiSelect';
@use 'select';
@use 'table/_table';
@use 'checkbox/_checkbox';
@use 'menu/_panelMenu';
@use 'menu/_menu';
@use 'dialog/_dialog';
@use 'toast/_toast';
@use 'switch/_switch';
@use 'tooltip/_tooltip';
@use 'progressSpinner/_progressSpinner';
@use 'selectButton/_selectButton';
@use 'datepicker/_datepicker';
@use '_tabs';
@use 'tag/_tag';
@use 'steps';
@use 'popover';
@use 'skeleton';
@use 'divider';
@use 'radio-button';
@use 'accordion';
@use 'badge';
@use '_breadcrumb';

.p-disabled,
.p-component:disabled {
  opacity: 0.65;
  cursor: default;
}

.p-placeholder {
  color: variables.$color-grey-3;
}
