@use 'sass:color';
@use 'variables';
@use 'mixins';

.p-button {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 0.75rem;
  height: 2rem;
  border-radius: variables.$border-radius-medium;

  &:not(.icon-only) shared-icon {
    margin-left: 0.5rem;
  }

  .p-button-label {
    text-align: left;
    @include mixins.font-xs();
  }

  &.icon-only {
    height: 2rem;
    width: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 1rem;
  }

  &:not(.p-button-outlined, .p-button-text) {
    border: 1px solid variables.$color-blue-active;
    background-color: variables.$color-blue-active;

    &:enabled:hover {
      background-color: color.adjust(variables.$color-blue-active, $lightness: -5%);
      border: 1px solid variables.$color-blue-active;
    }

    &.p-button-primary {
      border: 1px solid variables.$color-turquoise;
      background-color: variables.$color-turquoise;
      fill: variables.$color-white;

      &:enabled:hover {
        background-color: color.adjust(variables.$color-turquoise, $lightness: -5%);
        border: 1px solid variables.$color-turquoise;
      }
    }

    &.p-button-danger {
      border: 1px solid variables.$color-red;
      background-color: variables.$color-red;
      fill: variables.$color-white;

      &:enabled:hover {
        background-color: color.adjust(variables.$color-red, $lightness: 5.5%);
        border: 1px solid variables.$color-red;
      }
    }

    &.p-button-warn {
      border: 1px solid variables.$color-orange;
      background-color: variables.$color-orange;
      color: variables.$color-white;
      fill: variables.$color-white;

      &:enabled:hover {
        background-color: color.adjust(variables.$color-orange, $lightness: 5.5%);
        border: 1px solid variables.$color-orange;
        color: variables.$color-white;
      }
    }
  }

  &.p-button-outlined {
    &:enabled:hover {
      background-color: color.adjust(variables.$color-blue-active, $lightness: 37%) !important;
    }

    &.p-button-primary {
      border: 1px solid variables.$color-turquoise;
      color: variables.$color-turquoise;
      fill: variables.$color-turquoise;

      &:enabled:hover {
        background-color: color.adjust(variables.$color-turquoise, $lightness: 40%);
        border: 1px solid variables.$color-turquoise;
        color: variables.$color-turquoise;
      }
    }

    &.p-button-secondary {
      border: 1px solid variables.$color-grey-4;
      color: variables.$color-grey-4;
      fill: variables.$color-grey-4;

      &:enabled:hover {
        background-color: variables.$color-grey-7;
        border: 1px solid variables.$color-grey-2;
        color: variables.$color-grey-2;
      }
    }

    &.p-button-danger {
      border: 1px solid variables.$color-red;
      color: variables.$color-red;
      fill: variables.$color-red;

      &:enabled:hover {
        background-color: color.adjust(variables.$color-red, $lightness: 27.5%) !important;
        border: 1px solid variables.$color-red;
        color: variables.$color-red;
      }
    }

    &.p-button-warn {
      border: 1px solid variables.$color-orange !important;
      color: variables.$color-orange !important;
      fill: variables.$color-orange;

      &:enabled:hover {
        background-color: color.adjust(variables.$color-orange, $lightness: 40%) !important;
        border: 1px solid variables.$color-orange !important;
        color: variables.$color-orange !important;
      }
    }
  }

  &.p-button-small {
    font-size: variables.$font-size-s;
  }
}

// OLD STYLES
.p-button {
  color: #ffffff;
  background: #007bff;
  border: 1px solid #007bff;
  padding: 0.5rem 0.75rem;
  font-size: 1rem;
  transition:
    background-color 0.15s,
    border-color 0.15s,
    box-shadow 0.15s;
  border-radius: 4px;
}

.p-button:enabled:hover {
  background: #0069d9;
  color: #ffffff;
  border-color: #0069d9;
}

.p-button:enabled:active {
  background: #0062cc;
  color: #ffffff;
  border-color: #0062cc;
}

.p-button.p-button-outlined {
  background-color: transparent;
  color: #379dfa;
  border: 1px solid;
}

.p-button.p-button-outlined:enabled:hover {
  background: rgba(0, 123, 255, 0.04);
  color: #379dfa;
  border: 1px solid;
}

.p-button.p-button-outlined:enabled:active {
  background: rgba(0, 123, 255, 0.16);
  color: #379dfa;
  border: 1px solid;
}

.p-button.p-button-outlined.p-button-plain {
  color: #6c757d;
  border-color: #6c757d;
}

.p-button.p-button-outlined.p-button-plain:enabled:hover {
  background: #e9ecef;
  color: #6c757d;
}

.p-button.p-button-outlined.p-button-plain:enabled:active {
  background: #dee2e6;
  color: #6c757d;
}

.p-button.p-button-text {
  background-color: transparent;
  color: #379dfa;
  border-color: transparent;
}

.p-button.p-button-text:enabled:hover {
  background: rgba(0, 123, 255, 0.04);
  color: #379dfa;
  border-color: transparent;
}

.p-button.p-button-text:enabled:active {
  background: rgba(0, 123, 255, 0.16);
  color: #379dfa;
  border-color: transparent;
}

.p-button.p-button-text.p-button-plain {
  color: #6c757d;
}

.p-button.p-button-text.p-button-plain:enabled:hover {
  background: #e9ecef;
  color: #6c757d;
}

.p-button.p-button-text.p-button-plain:enabled:active {
  background: #dee2e6;
  color: #6c757d;
}

.p-button:focus {
  @include mixins.inputFocus();
}

.p-button .p-button-icon-left {
  margin-right: 0.5rem;
}

.p-button .p-button-icon-right {
  margin-left: 0.5rem;
}

.p-button .p-button-icon-bottom {
  margin-top: 0.5rem;
}

.p-button .p-button-icon-top {
  margin-bottom: 0.5rem;
}

.p-button .p-badge {
  margin-left: 0.5rem;
  min-width: 1rem;
  height: 1rem;
  line-height: 1rem;
}

.p-button.p-button-raised {
  box-shadow:
    0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.p-button.p-button-rounded {
  border-radius: 2rem;
}

.p-button.p-button-icon-only {
  width: 2.357rem;
  padding: 0;
  display: flex;
  justify-content: center;
}

.p-button.p-button-icon-only .p-button-icon-left,
.p-button.p-button-icon-only .p-button-icon-right {
  margin: 0;
}

.p-button.p-button-icon-only.p-button-rounded {
  border-radius: 50%;
  height: 2.357rem;
}

.p-button.p-button-sm {
  font-size: 0.875rem;
  padding: 0.4375rem 0.65625rem;
}

.p-button.p-button-sm .p-button-icon {
  font-size: 0.875rem;
}

.p-button.p-button-lg {
  font-size: 1.25rem;
  padding: 0.625rem 0.9375rem;
}

.p-button.p-button-lg .p-button-icon {
  font-size: 1.25rem;
}

.p-button.p-button-loading-label-only .p-button-label {
  margin-left: 0.5rem;
}

.p-button.p-button-loading-label-only .p-button-loading-icon {
  margin-right: 0;
}
