@use 'variables';

p-toast {
  .p-toast {
    width: auto !important;
  }

  .p-toast-top-center {
    min-height: 45px !important;
  }

  .p-toast .p-toast-message {
    max-height: 100%;
    min-height: 45px !important;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .p-toast .p-toast-message .p-toast-message-content {
    white-space: nowrap;
    width: 100%;
    max-width: 100vw;
    padding: 0 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .p-toast .toast-message,
  .toast-message-text {
    column-gap: 10px;
    row-gap: 6px;
  }

  .p-toast .p-toast-message.p-toast-message-success {
    border: none;
    background-color: variables.$color-green;
    color: #ffffff;

    .p-toast-icon-close {
      color: #ffffff;
      width: auto;
      height: auto;
      margin-left: 20px !important;
    }
  }

  .p-toast .p-toast-message.p-toast-message-error {
    border: none;
    background-color: variables.$color-red;
    color: #ffffff;

    .p-toast-icon-close {
      color: #ffffff;
      width: auto;
      height: auto;
      margin-left: 20px !important;
    }
  }

  .p-toast .p-toast-message.p-toast-message-warn {
    border: none;
    background-color: variables.$color-yellow-pale;
    color: #ffffff;

    .p-toast-icon-close {
      color: #ffffff;
      width: auto;
      height: auto;
      margin-left: 20px !important;
    }
  }

  .p-toast .p-toast-message.p-toast-message-info {
    border: none;
    background-color: variables.$color-grey-1;
    color: #ffffff;

    .p-toast-icon-close {
      color: #ffffff;
      width: auto;
      height: auto;
      margin-left: 20px !important;
    }
  }

  .p-toast-close-button {
    color: #ffffff !important;
    text-decoration: underline;
    margin: 0;
  }
}
