@use 'sass:math';
@use 'variables';

// FONTS
@mixin font-xxl {
  font-size: variables.$font-size-xxl;
  line-height: 42px;
  font-weight: 400;
  color: variables.$color-font-primary;
}

@mixin font-xl {
  font-size: variables.$font-size-xl;
  line-height: 28px;
}

@mixin font-l {
  font-size: variables.$font-size-l;
  line-height: 24px;
  font-weight: 300;
}

@mixin font-m {
  font-size: variables.$font-size-m;
  line-height: 21px;
}

@mixin font-s {
  font-size: variables.$font-size-xs;
  line-height: 16px;
}

@mixin font-13 {
  font-size: 13px;
  line-height: 16px;
}

@mixin font-xs {
  font-size: variables.$font-size-s;
  line-height: 18px;
}

@mixin font-xxs {
  font-size: variables.$font-size-xxs;
  line-height: 11px;
  font-weight: 500;
  color: variables.$color-grey-3;
  text-transform: uppercase;
}

// INPUTS
@mixin input {
  border: 1px solid variables.$input-border-color;
  color: variables.$color-font-primary;
  border-radius: variables.$border-radius-medium;
  background-color: variables.$color-white;
  min-height: variables.$input-height;
  font-size: variables.$font-size-s;
  padding: variables.$input-padding;
}

@mixin inputFocus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
  border-color: #007bff;
}

// TEXT TRANSFORMATION
@mixin ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// CHECKBOX
@mixin checkbox($size) {
  width: $size;
  height: $size;

  .p-checkbox-box {
    border: 1px solid variables.$color-border;
    background: #ffffff;
    width: $size;
    height: $size;
    color: #212529;
    border-radius: 4px;
    transition:
      background-color 0.15s,
      border-color 0.15s,
      box-shadow 0.15s;

    &:hover {
      border-color: variables.$color-blue-hover;
    }

    .p-checkbox-icon {
      color: variables.$color-blue-active;
      font-size: math.div($size, 2);
      line-height: 7px;
    }
  }

  .p-checkbox-checked .p-checkbox-box {
    border-color: variables.$color-blue-active;
    background: white;
  }
}

// TOOLTIP
@mixin tooltip-base() {
  background: variables.$color-white;
  color: variables.$color-font-primary;
  border-radius: 0.25rem;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  border: none;
}
