@use 'mixins';
@use 'variables';
@use 'sass:color';

$highlighted-row-color: color.adjust(variables.$color-turquoise, $lightness: 35%);

.horizontally-scrollable .p-datatable-table-container:after {
  content: '';
  width: 100%;
  height: 100%;
  background: linear-gradient(270deg, #f4f7f8, transparent 4rem);
  top: 0;
  left: 0;
  position: absolute;
  pointer-events: none;
  z-index: 3;
}

.vertically-scrollable .p-datatable-table-container:after {
  width: calc(100% - #{variables.$scrollbar-size});
}

.p-datatable {
  table {
    table-layout: auto !important;
  }

  th.p-sortable-column {
    cursor: default;

    &:focus {
      box-shadow: none;
    }
  }

  .p-datatable-table-container {
    position: unset;
  }

  &-tbody {
    & > tr {
      transition: box-shadow 1s;
      cursor: pointer;
      background: variables.$color-background-canvas;

      td {
        border-style: unset;
        border-top: variables.$primary-border;
        padding: 1rem 1rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    tr:hover,
    tr:focus {
      background: #ffffff;
      position: relative;
    }

    & > tr > td {
      @include mixins.font-xs();
    }
  }

  &-thead > tr > th {
    padding: 1rem 1rem 0.25rem;
    border: none;
    background: variables.$color-background-table-header;
    color: variables.$color-grey-2;
    @include mixins.font-xxs();
    height: 3rem;
    text-align: start;

    .sortable-column {
      display: flex;
      align-items: center;
      color: variables.$color-blue-active;
      cursor: pointer;
    }

    &:hover {
      background: variables.$color-blue-lighter;
    }
  }

  .pi-sort-alt:before {
    content: '';
  }

  .pi-sort-amount-up-alt:before {
    content: '';
  }

  .pi-sort-amount-down:before {
    content: '';
  }

  .p-datatable-tbody > tr {
    &.table-empty-message {
      background: none;
      cursor: default;

      td {
        text-align: center;
      }

      &:hover {
        box-shadow: none;
      }
    }

    &.table-skeleton {
      cursor: default;

      td {
        padding: 0 !important;
      }

      &:hover {
        box-shadow: none;
      }
    }

    &.p-highlight {
      background: $highlighted-row-color;

      &:hover {
        background: white;
      }
    }
  }
}

.p-paginator {
  border: none;
  background: none;
  color: variables.$color-grey-3;
  height: 3rem;
  display: flex;

  .p-paginator-current {
    margin-right: auto;
  }

  .p-paginator-pages {
    margin: 0.5rem;

    .p-paginator-page {
      font-size: variables.$font-size-m;
      margin: 0 0.25rem;

      &.p-paginator-page-selected {
        color: variables.$color-blue-active;
      }
    }
  }

  .p-paginator-first,
  .p-paginator-prev,
  .p-paginator-next,
  .p-paginator-last {
    width: 1.5rem;
    height: 1.5rem;
    font-size: 1.5rem;
    background-color: #379dfa;
    color: variables.$color-white;
    display: flex;
    margin: 0.125rem;
    border-radius: 50%;

    &.p-disabled {
      background-color: variables.$color-light-grey;
    }
  }

  .p-paginator-rpp-dropdown {
    width: 6rem;
    margin-left: auto;
  }
}

.p-datatable-frozen-column {
  position: sticky;
}

td.p-datatable-frozen-column {
  background: variables.$color-background-canvas;
  z-index: 2;
}

tr.hover > td.p-datatable-frozen-column {
  background: white;
}

tr td.p-frozen-column.actions {
  position: relative;
}

tr.hover td.p-frozen-column.actions {
  position: sticky;
  z-index: 4;
  background: transparent;
}

ap-common-table {
  display: flex;
  flex-direction: column;
  height: auto;
  overflow: hidden;
  flex-shrink: 5;
}

p-table {
  height: calc(100% - 5rem);
  min-height: 7.5rem;
}

.p-datatable {
  height: 100%;
}

.p-datatable-wrapper {
  height: 100%;
}

thead {
  position: sticky;
  top: 0;
  z-index: 3;
}

tr.p-highlight:not(:hover) td.p-datatable-frozen-column {
  background: $highlighted-row-color;
}
