@use 'mixins';

shared-labeled-value {
  .value {
    label {
      @include mixins.font-xxs();
    }

    span {
      font-weight: 400;
    }
  }
}
