@use 'variables';

p-tabs {
  .p-tab {
    padding: 0.5rem;
    width: auto;
    color: variables.$color-font-primary;
    cursor: pointer;
    border-radius: 0.5rem;
    background-color: rgba(variables.$color-blue-active, 0.05);
    border: none;

    .p-tab-icon {
      font-size: 1rem;
    }

    &.p-tab-active {
      color: variables.$color-blue-active;
      font-weight: variables.$font-weight-bold-500;
    }
  }

  .p-tablist-tab-list {
    border: none;
    gap: 1rem;
  }

  .p-tablist-nav-button {
    background-color: variables.$primary-bg-color;
    padding: 0 0.25rem;
  }
}
