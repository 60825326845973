@use 'variables';
@use 'mixins';

.p-inputnumber {
  width: 100%;

  .p-disabled,
  .p-component:disabled {
    opacity: 1;
    color: variables.$color-grey-2;
    background: variables.$color-grey-7;
  }
}

.p-input-icon-right input {
  width: 100%;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type='number'] {
  -moz-appearance: textfield; /* Firefox */
}
