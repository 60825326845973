@use 'mixins';

.p-tooltip {
  display: block;

  .p-tooltip-arrow {
    display: none;
  }

  .p-tooltip-text {
    @include mixins.tooltip-base;
    max-width: 225px;
    text-align: center;
    padding: 1rem 1.5rem;
    line-height: 130%;
  }
}
