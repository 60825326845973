@use 'variables';

p-datepicker {
  .p-datepicker {
    width: 100%;
  }

  .p-datepicker-dropdown {
    width: 2.357rem;
    padding: 0;
    display: flex;
    justify-content: center;
    border-radius: 0.375rem;
    color: variables.$color-white;
    background-color: variables.$color-blue-active;
  }

  .p-disabled,
  .p-component:disabled {
    opacity: 1;
    color: variables.$color-grey-2;

    &.p-inputtext {
      background: variables.$color-grey-7;
    }

    &.p-button {
      background: variables.$color-grey-4;
      color: white;
      border: 1px solid variables.$color-grey-4;
    }
  }
}

.p-datepicker-panel {
  background-color: variables.$color-white;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  border-radius: 1.25rem;
  overflow: hidden;

  .p-datepicker-header {
    background: variables.$color-grey-7;
    border: none;
    padding: 0.75rem 1rem;
    font-weight: 600;

    .p-datepicker-title {
      .p-datepicker-select-month {
        font-size: variables.$font-size-m;
        margin-right: 0.3rem;
      }

      .p-datepicker-select-year {
        font-size: variables.$font-size-m;
        padding-right: 0;
        padding-left: 0;
      }
    }
  }

  .p-datepicker-day-view,
  .p-datepicker-month-view,
  .p-datepicker-year-view {
    margin: 0 0.75rem 0.75rem 0.75rem;
    width: calc(100% - 1.5rem);
  }

  .p-datepicker-weekday-cell {
    padding: 0.5rem;

    .p-datepicker-weekday {
      font-size: variables.$font-size-s;
      font-weight: 500;
    }
  }

  .p-datepicker-day-cell {
    padding: 0 0.1rem;

    &.p-datepicker-today .p-datepicker-day:not(.p-datepicker-day-selected) {
      background: variables.$color-grey-6;

      &:hover {
        background: variables.$color-grey-5;
      }
    }
  }

  .p-datepicker-day,
  .p-datepicker-month,
  .p-datepicker-year {
    height: 2rem;
    border-radius: 0.375rem;
    font-size: variables.$font-size-s;

    &:hover {
      background: variables.$color-grey-6;
    }
  }

  .p-datepicker-day {
    width: 2rem;
  }

  .p-datepicker-day-selected {
    background: variables.$color-blue-light;
    font-weight: 700;
  }
}
