@use 'variables';
@use 'mixins';

p-select {
  @include mixins.input();
  width: 100%;
  display: flex;

  &.p-disabled,
  &.p-component:disabled {
    opacity: 1;
    color: variables.$color-grey-2;
    background: variables.$color-grey-7;
  }

  .p-select-label {
    align-content: center;
  }

  .p-select-dropdown-icon {
    margin-left: 0.5rem;
    color: variables.$color-grey-1;
    max-height: variables.$font-size-m;
  }

  .p-select-clear-icon {
    position: relative;
    margin: 0 0.5rem;
    top: unset;
  }

  .p-select-header {
    padding: 1.5rem 0 0 1.5rem;

    .p-iconfield {
      width: 100%;
    }

    .p-inputicon {
      top: calc(50% - 7px);
      right: 0.75rem;
      color: #495057;
    }
  }

  &:focus-within {
    @include mixins.inputFocus();
  }
}

p-select.ng-dirty.ng-invalid > .p-select {
  border-color: variables.$color-red;
}

.p-select-empty-message {
  padding: 0 1.5rem 0.5rem 1.5rem;
  text-align: center;
  font-size: variables.$font-size-m;
}

.p-select-overlay {
  padding-right: 1.5rem;
  padding-bottom: 1.5rem;
  background: variables.$color-white;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  border-radius: 1.25rem;

  .p-select-list-container {
    padding-top: 1.5rem;
    max-height: 350px !important;
  }

  .p-select-list {
    margin: 0 0.75rem 0 1rem;

    .p-select-option {
      color: variables.$color-font-primary;
      line-height: 19px;
      padding: 0.5rem 1rem;
      min-height: 48px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      opacity: 1;
      font-size: variables.$font-size-s;

      &.p-select-option-selected {
        color: variables.$color-font-primary;
        font-weight: variables.$font-weight-bold-600;
      }

      .p-dropdown-item-description {
        font-size: variables.$font-size-xs;
        color: variables.$color-grey-2;
        white-space: pre-line;
      }
    }
  }

  p-selectitem:not(:last-child) {
    .p-select-option {
      border-bottom: 1px solid variables.$color-grey-6;
    }
  }

  ::-webkit-scrollbar {
    width: 6px;
    border-radius: 30px;
  }

  ::-webkit-scrollbar-track {
    margin-top: 1.5rem;
    border-radius: 30px;
    background: variables.$color-grey-2;
  }

  ::-webkit-scrollbar-thumb:hover,
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: variables.$color-white;
    border: 1px solid variables.$color-grey-2;
  }
}
