@use 'mixins';
@use 'variables';

// FORM
.p-input-icon-right input {
  padding-right: 2rem;
}

.ap-form-section-hr {
  margin: 0 -24px;
}

// Do we really always want to expand textarea like this?
.textarea {
  height: 100%;
  width: 100%;
  resize: none;
}

.ap-form-width-570 {
  width: auto;
  max-width: 570px;
  min-width: 330px;
}

.ap-form-width-700 {
  width: auto;
  max-width: 700px;
  min-width: 600px;
}

.ap-icon-copy {
  fill: variables.$color-blue-active;
  margin-left: 4px;
  cursor: pointer;
}

.radio-buttons {
  display: flex;
  grid-gap: 1rem;
  align-items: center;
}

.p-input-icon-left,
.p-input-icon-right {
  min-width: 100% !important;
  position: relative;

  i {
    position: absolute;
    top: 50%;
    font-size: variables.$font-size-s;
    color: variables.$color-grey-2;
  }
}

.form-link {
  padding-top: 0.75rem;
  display: block;
  color: variables.$color-blue-active;
}

.form {
  display: grid;
  grid-gap: 1rem;
}

.form-skeleton {
  display: grid;
  grid-gap: 1rem;
  margin: 0.75rem 0;

  .form-skeleton__row-item {
    display: grid;
    grid-gap: 0.25rem;

    &.span-2 {
      grid-column: 1 / 3;
    }
  }
}

.form-row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 8px;
}

.form-buttons {
  justify-self: start;
  display: grid;
  grid-template-columns: 180px 70px;
  grid-gap: 12px;
}

.gender-selector {
  height: 32px;
  display: flex;
  grid-gap: 40px;
}

.readonly {
  width: 100%;

  & > div {
    height: 100%;
    max-width: calc(100% - 22px);
    line-height: 18px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

// GLOBAL INPUT CONTAINER
.ng-submitted .ng-invalid,
.ng-touched.ng-invalid,
.ng-submitted .ng-invalid:hover,
.ng-touched.ng-invalid:hover {
  &:not(div):not(form) {
    border-color: variables.$color-red;

    > span .p-component,
    > div .p-component,
    .p-multiselect {
      &:not(button):not(.p-select-header *) {
        border-color: variables.$color-red !important;
      }
    }
  }
}

// OLD
.multistep-buttons {
  margin-top: 32px;
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;

  button {
    width: fit-content;
  }
}

.step-container {
  background-color: #fff;
  width: 63.25rem;
  border-radius: 8px;
  box-shadow: 0 1px 3px #8a8f9533;
  margin-right: 40px;
  padding: 24px;
}

.step-body {
  padding: 32px 0;
}
