@use 'mixins';
@use 'variables';

.p-inputtext {
  @include mixins.input();
  padding: variables.$input-padding;
  box-sizing: border-box;
  outline: none;
  width: 100%;
}

.p-inputtext:enabled:hover {
  border-color: #ced4da;
}

.p-inputtext:enabled:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
  border-color: #007bff;
}

.p-inputtext.ng-dirty.ng-invalid {
  border-color: #dc3545;
}
