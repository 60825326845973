@use 'variables';
@use 'mixins';

shared-file-selector {
  .allowed-types {
    padding-left: 0 !important;
    padding-right: 0 !important;
    color: variables.$color-grey-3 !important;
  }

  .first-file-upload {
    border: 1px dashed variables.$color-blue-active !important;
    transition: 0.3s ease all;

    &:hover {
      background: variables.$color-grey-7 !important;
    }
  }

  .delete-icon-btn {
    svg {
      fill: variables.$color-blue-active !important;
    }

    &:focus {
      background: variables.$color-grey-6 !important;
    }

    &:hover {
      background: variables.$color-grey-7 !important;
    }
  }

  .upload-hint .highlighted {
    color: variables.$color-blue-active !important;
  }
}

shared-file-selector.ng-invalid.ng-touched {
  .first-file-upload {
    border: 1px dashed variables.$color-red !important;
    background: rgba(variables.$color-red, 0.1) !important;
  }
}

.ng-submitted {
  shared-file-selector.ng-invalid {
    .first-file-upload {
      border: 1px dashed variables.$color-red !important;
      background: rgba(variables.$color-red, 0.1) !important;
    }
  }
}
