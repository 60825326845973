@use 'variables';
@use 'mixins';

p-input {
  display: block;
  height: variables.$input-height !important;
  width: 100%;

  .p-disabled,
  .p-component:disabled {
    opacity: 1;
    color: variables.$color-grey-2;
    background: variables.$color-grey-7;
  }

  &-icon-right {
    width: 100%;

    .p-inputtext {
      width: 100%;
      height: 32px;

      .p-component.p-element.ng-star-inserted.p-filled:not[disable] {
        margin: 0 -1rem;
      }

      padding: 0.5rem 2rem 0.5rem 1rem;
    }

    & > i:last-of-type {
      color: variables.$color-grey-2;
    }

    ::placeholder {
      color: variables.$color-grey-3;
    }
  }
}

.p-input-icon-right > i:last-of-type {
  right: 0.75rem;
  margin-top: -0.5rem;
}
