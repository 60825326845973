@use 'variables';

.p-panelmenu-icon {
  margin-right: 20px;
}

.p-panelmenu {
  overflow: scroll;

  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }

  .p-panelmenu-panel:nth-last-child(3) {
    margin-top: auto;
  }

  .expandable {
    .p-panelmenu-icon {
      font-size: 12px;
      margin-right: 8px;
    }

    .p-menuitem-text {
      text-transform: uppercase;
      font-weight: 700;
      font-size: 12px;
    }
  }

  .p-menuitem-text {
    font-weight: 300;
  }

  .p-panelmenu-content {
    .p-menuitem-link {
      padding-left: 24px;
    }
  }

  .p-panelmenu-header-link,
  .p-menuitem-link {
    padding: 8px 0 8px 32px;
    margin: 8px 0;
    color: variables.$color-grey-3;
    z-index: 2;
    width: 212px;

    .p-menuitem-icon {
      margin-right: 16px;
      font-size: 24px;
    }

    &.p-menuitem-link-active {
      color: variables.$color-blue-active;

      .p-menuitem-text {
        color: variables.$color-blue-active;
      }
    }

    &:hover {
      background: transparent;
      color: #379dfacc;
      cursor: default;
    }
  }

  .p-toggleable-content {
    padding: 0 24px;
  }
}
