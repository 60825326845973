@use 'variables';

p-accordion {
  background-color: variables.$color-white;
  display: block;

  .p-accordionpanel {
    border-style: unset;

    &:not(:last-child) {
      border-bottom: variables.$primary-border;
    }

    &:hover,
    &.p-accordionpanel-active {
      background-color: rgba(variables.$color-blue-light, 0.1);
      .p-accordionheader {
        font-weight: 700;
      }
    }
  }

  .p-accordionpanel-active {
    .p-accordioncontent {
      &:not(.ng-animating) {
        overflow: visible;
      }
    }
  }

  .p-accordionheader {
    padding: 1rem 1.25rem;
    border: none;
    color: variables.$color-blue-active;
    font-size: 0.875rem;
    transition: box-shadow 0.15s;
  }

  .p-accordionheader-toggle-icon {
    margin-right: 0;
    position: absolute;
    right: 0.5rem;
    font-size: 0.75rem;
  }

  .p-accordioncontent-content {
    border-style: unset;
    padding: 1.25rem;
  }
}
