@use 'variables';

.p-radiobutton {
  width: 20px;
  height: 20px;

  .p-radiobutton-box {
    border: 1px solid variables.$color-border;
    background: #ffffff;
    width: 18px;
    height: 18px;
    color: #212529;
    border-radius: 50%;
    transition:
      background-color 0.15s,
      border-color 0.15s,
      box-shadow 0.15s;

    &:not(.p-disabled) {
      &.p-focus {
        outline: 0 none;
        outline-offset: 0;
        border-color: white;
      }

      &:not(.p-highlight):hover {
        border-color: variables.$color-blue-hover;
      }
    }

    &.p-highlight {
      border-color: variables.$color-border;
      background: white;

      &:not(.p-disabled):hover {
        border-color: variables.$color-border;
        background: variables.$color-blue-light;
        color: variables.$color-blue-active;
      }
    }

    .p-radiobutton-icon {
      width: 10px;
      height: 10px;
      transition-duration: 0.15s;
    }
  }

  &.p-radiobutton-checked .p-radiobutton-icon {
    background-color: variables.$color-blue-active;
  }

  &.ng-dirty.ng-invalid > .p-radiobutton > .p-radiobutton-box {
    border-color: variables.$color-red;
  }
}

.p-radiobutton-label-active {
  color: variables.$color-blue-active;
}
