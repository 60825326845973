/** WARNING: Do NOT import this file in any component style */
@use 'variables';
@use 'mixins';
@use 'colors';

@use 'shared_lib_overrides/shared_overrides';

@use 'sass:meta';
@use 'primeng-overrides/overrides';

@use 'detail';
@use 'font-icons';
@use 'dialog';

@use 'form';
@import url('https://fonts.googleapis.com/css?family=Roboto:100,100italic,300,300italic,400,400italic,500,500italic,700,700italic,900,900italic&subset=latin,latin-ext,cyrillic,cyrillic-ext,greek-ext,greek,vietnamese');
@include meta.load-css('indexed-list');

::placeholder {
  color: variables.$color-grey-3;
}

html,
body {
  height: 100%;
  overflow: hidden;
}

body,
h1,
h2,
h3,
h4,
h5 {
  line-height: 1;
  margin: 0;
  padding: 0;
}

* {
  font-family: 'Roboto', 'Lucida Grande', 'Lucida Sans Unicode', 'Lucida Sans', 'Tahoma', sans-serif;
  outline: none;
  box-sizing: border-box;
}

hr {
  border-top: 1px solid variables.$color-border;
  border-bottom: none;
  border-right: none;
  border-left: none;
  margin: 0 auto;
}

textarea {
  @include mixins.input();
  padding: 0.5rem 0.75rem;
}

textarea:disabled {
  color: variables.$color-grey-2 !important;
  background-color: #fbfbfd !important;
  box-shadow: inset 0 1px 2px 0 rgba(202, 204, 210, 0.2) !important;
}

a {
  color: inherit;
  text-decoration: none;
}

h1 {
  align-self: center;
  font-size: 36px;
  line-height: 42px;
  font-weight: 400;
  color: black;
}

h5 {
  font-weight: 400;
}

@keyframes fadeInFromNone {
  0% {
    display: none;
    opacity: 0;
  }

  1% {
    display: block;
    opacity: 0;
  }

  100% {
    display: block;
    opacity: 1;
  }
}

/* stylelint-enable selector-max-type */

.ap-input {
  width: 100%;
  height: 48px;
  border-radius: 8px;
  border: solid 1px rgba(128, 142, 153, 0.15);
  background-color: #ffffff;
  padding: 10px 16px;
}

.ap-label {
  font-size: 14px;
  line-height: 1.29;
  color: variables.$color-grey-2;
}

.ap-input-label {
  @extend .ap-label;
  display: block;
}

.ap-loading-container {
  position: absolute;
  left: 0;
  top: 0;
  margin-top: 40px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: variables.$color-grey-3;
  background-color: rgba(variables.$primary-bg-color, 0.6);
  animation: fadeInFromNone 0.16s ease-in-out;
  z-index: 200;
}

.green-back {
  background-color: variables.$color-turquoise;
}

.gray-back {
  background-color: variables.$color-grey-3;
}

.success-back {
  background-color: variables.$color-green;
}

.danger-back {
  background-color: variables.$color-red;
}

.warn-back {
  background-color: variables.$color-orange;
}

.orange {
  color: variables.$color-yellow-pale;
}

.green {
  color: variables.$color-turquoise;
}

.purple {
  color: variables.$color-purple;
}

.gray {
  color: variables.$color-grey-3;
}

.gray-1 {
  color: variables.$color-grey-1;
}

.blue {
  color: variables.$color-blue-dashly;
}

.d-flex {
  display: flex;
}

.d-block {
  display: block;
}

::-webkit-scrollbar {
  width: variables.$scrollbar-size;
  height: variables.$scrollbar-size;
}

::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0);
}

::-webkit-scrollbar-thumb {
  height: 6px;
  border: 6px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  opacity: 0.5;
  background-color: rgba(#95a5b2, 0.2);
  border-radius: 14px;
}

::-webkit-scrollbar-thumb:hover {
  background: rgba(#95a5b2, 0.5);
  height: 6px;
  border: 6px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  border-radius: 14px;
}

::-webkit-scrollbar-button {
  width: 0;
  height: 0;
  display: none;
}

::-webkit-scrollbar-corner {
  background-color: transparent;
}
