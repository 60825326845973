@use 'variables';
@use 'mixins';

.ap-indexed-list {
  display: grid;
  grid-column: span 2;
}

.ap-indexed-list-header,
.ap-indexed-list-row {
  display: grid;
  padding: 0.375rem 0.5rem;
  align-items: center;
  line-height: 1.5rem;
  text-align: center;
  font-size: variables.$font-size-s;
}

.ap-indexed-list-header {
  @include mixins.font-xxs();
}

.ap-indexed-list-row:not(:last-child) {
  border-bottom: variables.$primary-border;
}

.ap-list-line-field {
  align-self: center;
  justify-self: center;
  display: flex;
  align-items: center;
  position: relative;
  height: 100%;
  width: 100%;

  .circle {
    position: absolute;
    border: 2px solid variables.$color-turquoise;
    background-color: variables.$color-white;
    border-radius: 0.5rem;
    height: 0.5rem;
    width: 0.5rem;
    left: 0;
    right: 0;
    margin: auto;
  }

  .line {
    position: absolute;
    height: calc(100% + 0.5rem);
    border-right: 2px dotted variables.$color-turquoise;
    top: -0.25rem;
    left: 0;
    right: 0;
    margin: auto;
    width: 2px;
  }
}
