@use 'variables';
@use 'mixins';

p-selectbutton {
  @include mixins.input();

  display: inline-flex;
  width: fit-content;
  padding: 0;
  border: 0;

  .p-togglebutton:not(.p-button-outlined, .p-button-text) {
    height: 100%;
    width: auto;
    padding: 0.25rem 0.75rem;
    @include mixins.font-s();
    color: variables.$color-grey-3;
    background-color: #ffffff;
    transition:
      background-color 0.15s,
      border-color 0.15s,
      box-shadow 0.15s;

    &.p-togglebutton-checked {
      background: variables.$color-blue-active;
      color: #ffffff;
    }

    &.p-togglebutton-checked:hover {
      background: variables.$color-blue-light;
      color: #ffffff;
    }

    &:not(.p-disabled):not(.p-togglebutton-checked):hover {
      background: variables.$color-blue-light;
      color: #ffffff;
    }
  }

  p-togglebutton {
    border-top: 1px solid variables.$input-border-color;
    border-bottom: 1px solid variables.$input-border-color;
    overflow: hidden;

    &:not(:last-child) {
      border-right: variables.$primary-border;
    }

    &:first-child {
      border-left: 1px solid variables.$input-border-color;
      border-top-left-radius: variables.$border-radius-medium;
      border-bottom-left-radius: variables.$border-radius-medium;
    }

    &:last-child {
      border-right: 1px solid variables.$input-border-color;
      border-top-right-radius: variables.$border-radius-medium;
      border-bottom-right-radius: variables.$border-radius-medium;
    }

    &:focus-within {
      @include mixins.inputFocus();
      z-index: 2;
      border-color: variables.$input-border-color;
    }
  }
}
