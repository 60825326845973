@use 'variables';

p-steps {
  .p-steps .p-steps-item.p-steps-item-active .p-steps-item-number {
    background: variables.$color-blue-active;
  }

  .p-steps .p-steps-item .p-steps-item-link {
    background: transparent;
    transition: box-shadow 0.15s;
  }

  .p-steps .p-steps-item .p-steps-item-link .p-steps-item-number {
    display: flex;
    align-items: center;
    justify-content: center;
    color: variables.$color-grey-1;
    border: 1px solid variables.$color-border;
    min-width: 2rem;
    height: 2rem;
    line-height: 2rem;
    font-size: 1.125rem;
    z-index: 1;
    border-radius: 50%;
    background: variables.$color-white;
  }

  .p-steps .p-steps-item .p-steps-item-link .p-steps-item-label {
    margin-top: 0.5rem;
    color: variables.$color-grey-1;
  }

  .p-steps .p-steps-item.p-steps-item-active .p-steps-item-number {
    background: variables.$color-blue-active;
    color: variables.$color-white;
  }

  .p-steps .p-steps-item.p-steps-item-active .p-steps-item-label {
    color: variables.$color-font-primary;
  }

  .p-steps .p-steps-item:before {
    content: ' ';
    border-top: 1px solid variables.$color-border;
    top: 2rem;
    left: 0;
    display: block;
    position: absolute;
  }

  .p-disabled {
    opacity: 1;
  }
}
