@use 'variables';
@use 'mixins';

.tool-description {
  display: flex;
  width: 1200px;
  margin-bottom: 8px;
}

h1 {
  @include mixins.font-xxl();
  margin-bottom: 24px;
}

h2 {
  @include mixins.font-l();
  font-weight: 400;
}

.detail-wrapper {
  display: flex;
  align-items: flex-start;

  .left-content {
    min-width: 540px;
    max-width: 36rem;
    position: relative;

    .mortgage-detail-link {
      position: absolute;
      top: -2.5rem;
      right: 0.625rem;
    }
  }

  .right-content {
    margin-left: 1rem;
    min-width: 430px;
    max-width: 30rem;
  }
}

.form-container {
  background-color: white;
  border: 1px solid variables.$color-border;
  border-radius: 6px;
  width: 570px;

  > *:last-child {
    border-bottom: none;
  }
}

.section-wrapper {
  margin-top: 2rem;
}

.section {
  padding: 16px 24px;
  position: relative;

  &:not(:last-child) {
    border-bottom: 1px solid variables.$color-border;
  }

  .saving-indicator {
    position: absolute;
    top: 0;
    right: 0;
    @include mixins.font-xxs();
    color: white;
    padding: 4px 8px;
    letter-spacing: 0.1em;
    border-bottom-left-radius: 4px;

    &__in-progress {
      background: variables.$color-grey-7;
      width: 100px;
      display: flex;
      justify-content: center;
    }

    &__saved {
      background: variables.$color-green;
      opacity: 0;
    }
  }

  &.no-padding-bottom {
    padding-bottom: 0;
  }
}

.readonly-field {
  display: flex;
  flex-direction: column;
  grid-gap: 0.25rem;

  .readonly-field-label {
    @include mixins.font-xxs();
    cursor: inherit;
  }

  .readonly-field-value {
    @include mixins.font-m();
    line-height: 1.2rem;
    font-weight: 400;
  }
}
