@use 'variables';
@use 'mixins';

shared-form-field-wrapper {
  & > div {
    width: 100%;
    display: grid;
    grid-gap: 0.5rem;
  }

  .rows {
    grid-template-rows: max-content max-content min-content;

    .content {
      height: max-content;
    }
  }

  .inline {
    grid-template-columns: max-content auto;
    align-items: center;

    .content {
      display: flex;
      justify-content: flex-end;
    }

    .validation-messages {
      grid-column-start: 2;
    }
  }

  .ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &:has(shared-file-selector),
  &:has(ap-simple-html-editor) {
    grid-template-rows: 1rem auto min-content;
  }

  .form-field-label {
    height: max-content;
    @include mixins.font-xxs();
    display: flex;
    align-items: self-end;

    shared-icon {
      overflow: visible;
      margin-left: 0.25rem;
      font-size: variables.$font-size-s;
      fill: variables.$color-grey-2;
    }
  }

  .custom-validation-message {
    display: block;
    color: #ff2d2d;
    font-size: 0.75rem;
    font-weight: 500;
    line-height: 1rem;
    padding-left: 0.3rem;
  }
}
