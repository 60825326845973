@use 'mixins';
@use 'variables';

p-checkbox,
p-tableHeaderCheckbox,
p-tableCheckbox {
  display: inline-flex;

  &:not(.p-checkbox-disabled) {
    .p-checkbox-box.p-focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
      border-color: #007bff;
    }

    .p-checkbox-box.p-highlight:hover {
      border-color: variables.$color-blue-hover;
    }
  }

  &.ng-dirty.ng-invalid > .p-checkbox > .p-checkbox-box {
    border-color: #dc3545;
  }

  .p-checkbox {
    width: 16px;
    height: 16px;

    &.p-checkbox-checked .p-checkbox-box {
      border-color: variables.$color-blue-active;
      background: white;
    }

    .p-checkbox-box {
      border: 1px solid variables.$color-border;
      background: #ffffff;
      width: 16px;
      height: 16px;
      color: #212529;
      border-radius: 4px;
      transition:
        background-color 0.15s,
        border-color 0.15s,
        box-shadow 0.15s;

      &:hover {
        border-color: variables.$color-blue-hover;
      }

      .p-checkbox-icon {
        transition-duration: 0.15s;
        color: variables.$color-blue-active;
        font-size: 8px;
      }
    }
  }

  .p-checkbox-label {
    @include mixins.font-m();
    margin-left: 8px;
    cursor: pointer;
  }
}
