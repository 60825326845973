// FONT SIZES
$font-size-xxl: 2.25rem;
$font-size-xl: 1.5rem;
$font-size-l: 1.3125rem;
$font-size-m: 1rem;
$font-size-s: 0.875rem;
$font-size-xs: 0.75rem;
$font-size-xxs: 0.6875rem;

// FONT SIZES
$font-weight-bold-500: 500;
$font-weight-bold-600: 600;
$font-weight-bold-700: 700;

// MAIN COLORS
$color-blue-dashly: #015891;
$color-blue-active: #379dfa;
$color-blue-light: #a8d5ff;
$color-blue-lighter: #c5deff;
$color-blue-hover: #5eaefa;

$color-grey-1: #7d8892;
$color-grey-2: #98a5b1;
$color-grey-3: #aab5bc;
$color-grey-4: #bfbfbf;
$color-grey-5: #c4d3d8;
$color-grey-6: #e7eef1;
$color-grey-7: #f5f7f8;
$color-light-grey: #d8e1e4;

$color-turquoise: #52bed1;
$color-green: #70d7c4;
$color-green-active: #22cb58;
$color-yellow: #f4c102;
$color-yellow-pale: #f5bf84;
$color-orange: #f58a1f;
$color-red: #ff6868;
$color-light-red: #f6e1e1;
$color-purple: #b037fa;
$color-purple-2: #a273f0;
$color-purple-light: #d6c5f1;
$color-white: #ffffff;
$color-white-active: #fafbfc;
$color-background-canvas: $color-grey-7;
$color-background-green: #eaf9f6;
$color-background-table-header: #e3eff9;
$color-font-primary: black;
$color-border: #e0e4e6;

$primary-border: 1px solid $color-border;
$border-radius-small: 0.375rem;
$border-radius-medium: 0.5rem;
$primary-box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 10%);

$scrollbar-size: 1.2rem;

$primary-bg-color: #f4f7f8;

// FORM
$input-height: 2rem;
$input-padding: 0.438rem 0.75rem;
$input-border-color: #ced4da;
$input-border-radius: 6px;

// OTHER
$content-max-width: 63.25rem;
