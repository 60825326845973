@use 'variables';

shared-checkbox {
  display: flex;
  gap: 0.5rem;

  label {
    line-height: 1.1;
    cursor: pointer;
  }
}
