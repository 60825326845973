@use 'variables';

// TEXT
.color-blue-active {
  color: variables.$color-blue-active;
}

.color-blue-hover:hover {
  color: variables.$color-blue-hover;
}

.color-grey-1 {
  color: variables.$color-grey-1;
}

.color-grey-2 {
  color: variables.$color-grey-2;
}

.color-grey-3 {
  color: variables.$color-grey-3;
}

.color-grey-5 {
  color: variables.$color-grey-5;
}

.color-orange {
  color: variables.$color-orange;
}

.color-green {
  color: variables.$color-green;
}

// BACKGROUND
.bg-turquoise {
  background-color: variables.$color-turquoise;
}

.bg-white {
  background-color: variables.$color-white;
}

// FILL
.fill-blue-active {
  fill: variables.$color-blue-active;
}

.fill-turquoise {
  fill: variables.$color-turquoise;
}

.fill-green {
  fill: variables.$color-green;
}

.fill-orange {
  fill: variables.$color-orange;
}

.fill-gray {
  fill: variables.$color-grey-3;
}

// BORDER
.border-color-light-grey {
  border-color: variables.$color-light-grey;
}
