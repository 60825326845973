@use 'variables';

shared-property-address-select {
  position: relative;
  display: flex;
  font-size: 14px;
  grid-area: content;
  border: none;
  border-radius: 6px;
  background-color: white;
  height: 32px;
  width: 100%;

  > span input {
    width: 100%;
    height: 100%;
    padding: 0.25rem 1.25rem 0.25rem 1.25rem;
    font-size: 14px;
  }
}

.found-addresses {
  width: 100%;
  min-width: 360px;
  position: absolute;
  max-height: 20rem;
  top: 34px;
  left: 0;
  z-index: 2;
  overflow: auto;
  border: 1px solid #edeff0;
  border-radius: 8px;
  background: white;
  box-shadow: 0 1px 3px 0 rgba(138, 143, 149, 0.2);

  .found-address {
    display: flex;
    height: 2rem;
    border-bottom: 1px solid #edeff0;
    align-items: center;
    padding: 0 20px;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 16px;
    transition: all ease 0.2s;

    .address-post-code {
      margin-right: 0.5rem;
    }

    .address-street {
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .address-count {
      margin-left: auto;
      margin-right: 0;
      color: variables.$color-grey-3;
      transition: color ease 0.2s;
    }

    &:hover {
      background: variables.$color-blue-hover;
      color: white;
      cursor: pointer;

      .address-count {
        color: white;
      }
    }
  }
}
