@use 'variables';

p-toggleswitch {
  height: 1.125rem;
}

.p-toggleswitch {
  height: 1.125rem;

  &.p-toggleswitch-checked .p-toggleswitch-slider {
    background: variables.$color-blue-active;

    .p-toggleswitch-handle {
      transform: translateX(1.4rem);
      left: -0.25rem;
    }

    &:hover {
      background: variables.$color-blue-hover;
    }
  }

  .p-toggleswitch-input {
    height: 1.125rem;
  }

  .p-toggleswitch-slider {
    border-style: unset;
    background: variables.$color-grey-5;
    height: 1.125rem;
    width: 2.25rem;
    border-radius: 0.875rem;
    transition-duration: 0.15s;
  }

  .p-toggleswitch-handle {
    width: 0.875rem;
    height: 0.875rem;
    top: 0.125rem;
    left: 0.188rem;
    border-radius: 0.875rem;
    background: variables.$color-white;
    transition-duration: 0.15s;
  }
}
