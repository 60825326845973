@font-face {
  font-family: 'icomoon';
  src: url('../assets/fonts/icomoon.eot');
  src:
    url('../assets/fonts/icomoon.eot') format('embedded-opentype'),
    url('../assets/fonts/icomoon.ttf') format('truetype'),
    url('../assets/fonts/icomoon.woff') format('woff'),
    url('../assets/fonts/icomoon.svg') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-person-with-hairline:before {
  content: '\e959';
}
.icon-chart:before {
  content: '\e95a';
}
.icon-gauge:before {
  content: '\e95b';
}
.icon-hand-with-house:before {
  content: '\e95c';
}
.icon-wifi-antenna:before {
  content: '\e95d';
}
.icon-electricity:before {
  content: '\e95e';
}
.icon-shopping:before {
  content: '\e95f';
}
.icon-unsecured-loan:before {
  content: '\e960';
}
.icon-secured-loan:before {
  content: '\e961';
}
.icon-house-mortgage:before {
  content: '\e962';
}
.icon-role-agent:before {
  content: '\e958';
}
.icon-person-with-arrows:before {
  content: '\e957';
}
.icon-computer:before {
  content: '\e908';
}
.icon-command-line:before {
  content: '\e952';
}
.icon-superman:before {
  content: '\e953';
}
.icon-hourglass:before {
  content: '\e954';
}
.icon-hand-with-message:before {
  content: '\e955';
}
.icon-pipeline:before {
  content: '\e956';
}
.icon-donut-chart:before {
  content: '\e903';
}
.icon-map:before {
  content: '\e906';
}
.icon-person-in-focus:before {
  content: '\e900';
}
.icon-mortgage:before {
  content: '\e902';
}
.icon-retry:before {
  content: '\e951';
}
.icon-person:before {
  content: '\e94f';
}
.icon-import:before {
  content: '\e950';
}
.icon-network:before {
  content: '\e94e';
}
.icon-circle-check:before {
  content: '\e94b';
}
.icon-archive:before {
  content: '\e94c';
}
.icon-triangle-exclamation:before {
  content: '\e94d';
}
.icon-eye:before {
  content: '\e94a';
}
.icon-closed-sign:before {
  content: '\e92f';
}
.icon-key:before {
  content: '\e949';
}
.icon-arrow-up-right-from-square:before {
  content: '\e948';
}
.icon-card-view:before {
  content: '\e946';
}
.icon-list-view:before {
  content: '\e947';
}
.icon-full-arrow-down:before {
  content: '\e944';
}
.icon-full-arrow-up:before {
  content: '\e945';
}
.icon-bulk-actions:before {
  content: '\e943';
}
.icon-arrow-up:before {
  content: '\e93f';
}
.icon-arrow-down:before {
  content: '\e940';
}
.icon-arrow-left:before {
  content: '\e941';
}
.icon-arrow-right:before {
  content: '\e942';
}
.icon-shield-check:before {
  content: '\e937';
}
.icon-circle-plus:before {
  content: '\e938';
}
.icon-pause:before {
  content: '\e93d';
}
.icon-play:before {
  content: '\e93e';
}
.icon-coins:before {
  content: '\e93b';
}
.icon-house:before {
  content: '\e93c';
}
.icon-report:before {
  content: '\e93a';
}
.icon-role-first-level:before {
  content: '\e932';
}
.icon-role-forth-level:before {
  content: '\e933';
}
.icon-role-third-level:before {
  content: '\e934';
}
.icon-role-second-level:before {
  content: '\e935';
}
.icon-group:before {
  content: '\e92c';
}
.icon-reassign:before {
  content: '\e92d';
}
.icon-stop-hand:before {
  content: '\e92e';
}
.icon-edit-filled:before {
  content: '\e930';
}
.icon-trash-bin:before {
  content: '\e931';
}
.icon-circle:before {
  content: '\e936';
}
.icon-back:before {
  content: '\e939';
}
.icon-copy:before {
  content: '\e92b';
}
.icon-hand-person-outline:before {
  content: '\e901';
}
.icon-settings-outline:before {
  content: '\e904';
}
.icon-notification:before {
  content: '\e905';
}
.icon-calendar-outline:before {
  content: '\e907';
}
.icon-upload-outline:before {
  content: '\e909';
}
.icon-filter-outline:before {
  content: '\e90a';
}
.icon-pause-outline:before {
  content: '\e90b';
}
.icon-document-find:before {
  content: '\e90c';
}
.icon-menu-outline:before {
  content: '\e90d';
}
.icon-find-outline:before {
  content: '\e90e';
}
.icon-person-plus:before {
  content: '\e90f';
}
.icon-percent-tag:before {
  content: '\e910';
}
.icon-hand-person:before {
  content: '\e911';
}
.icon-three-dots:before {
  content: '\e912';
}
.icon-statistics:before {
  content: '\e913';
}
.icon-lighthouse:before {
  content: '\e914';
}
.icon-power-off:before {
  content: '\e915';
}
.icon-checkmark:before {
  content: '\e916';
}
.icon-briefcase:before {
  content: '\e917';
}
.icon-download:before {
  content: '\e918';
}
.icon-document:before {
  content: '\e919';
}
.icon-unicorn:before {
  content: '\e91a';
}
.icon-diamond:before {
  content: '\e91b';
}
.icon-people:before {
  content: '\e91c';
}
.icon-pencil:before {
  content: '\e91d';
}
.icon-folder:before {
  content: '\e91e';
}
.icon-avatar:before {
  content: '\e91f';
}
.icon-phone:before {
  content: '\e920';
}
.icon-medal:before {
  content: '\e921';
}
.icon-cross:before {
  content: '\e922';
}
.icon-plus:before {
  content: '\e923';
}
.icon-mail:before {
  content: '\e924';
}
.icon-link:before {
  content: '\e925';
}
.icon-info:before {
  content: '\e926';
}
.icon-gear:before {
  content: '\e927';
}
.icon-edit-outline:before {
  content: '\e928';
}
.icon-bell:before {
  content: '\e929';
}
.icon-bank:before {
  content: '\e92a';
}
