/*.p-error {*/
/*  color: #dc3545;*/
/*}*/

/*.p-text-secondary {*/
/*  color: #6c757d;*/
/*}*/

/*.pi {*/
/*  font-size: 1rem;*/
/*}*/

/*.p-link {*/
/*  font-size: 1rem;*/
/*  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',*/
/*    'Segoe UI Symbol';*/
/*  border-radius: 4px;*/
/*}*/

/*.p-link:focus {*/
/*  outline: 0 none;*/
/*  outline-offset: 0;*/
/*  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);*/
/*}*/

/*.p-autocomplete .p-autocomplete-loader {*/
/*  right: 0.75rem;*/
/*}*/

/*.p-autocomplete.p-autocomplete-dd .p-autocomplete-loader {*/
/*  right: 3.107rem;*/
/*}*/

/*.p-autocomplete .p-autocomplete-multiple-container {*/
/*  padding: 0.25rem 0.75rem;*/
/*}*/

/*.p-autocomplete .p-autocomplete-multiple-container:not(.p-disabled):hover {*/
/*  border-color: #ced4da;*/
/*}*/

/*.p-autocomplete .p-autocomplete-multiple-container:not(.p-disabled).p-focus {*/
/*  outline: 0 none;*/
/*  outline-offset: 0;*/
/*  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);*/
/*  border-color: #007bff;*/
/*}*/

/*.p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-input-token {*/
/*  padding: 0.25rem 0;*/
/*}*/

/*.p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-input-token input {*/
/*  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',*/
/*    'Segoe UI Symbol';*/
/*  font-size: 1rem;*/
/*  color: #212529;*/
/*  padding: 0;*/
/*  margin: 0;*/
/*}*/

/*.p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-token {*/
/*  padding: 0.25rem 0.75rem;*/
/*  margin-right: 0.5rem;*/
/*  background: #007bff;*/
/*  color: #ffffff;*/
/*  border-radius: 4px;*/
/*}*/

/*.p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-token .p-autocomplete-token-icon {*/
/*  margin-left: 0.5rem;*/
/*}*/

/*p-autocomplete.ng-dirty.ng-invalid > .p-autocomplete > .p-inputtext {*/
/*  border-color: #dc3545;*/
/*}*/

/*.p-autocomplete-panel {*/
/*  background: #ffffff;*/
/*  color: #212529;*/
/*  border: 1px solid rgba(0, 0, 0, 0.15);*/
/*  border-radius: 4px;*/
/*  box-shadow: none;*/
/*}*/

/*.p-autocomplete-panel .p-autocomplete-items:not(.p-autocomplete-virtualscroll) {*/
/*  padding: 0.5rem 0;*/
/*}*/

/*.p-autocomplete-panel .p-autocomplete-items.p-autocomplete-virtualscroll .cdk-virtual-scroll-content-wrapper {*/
/*  padding: 0.5rem 0;*/
/*}*/

/*.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item {*/
/*  margin: 0;*/
/*  padding: 0.5rem 1.5rem;*/
/*  border: 0 none;*/
/*  color: #212529;*/
/*  background: transparent;*/
/*  transition: box-shadow 0.15s;*/
/*  border-radius: 0;*/
/*}*/

/*.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item:hover {*/
/*  color: #212529;*/
/*  background: #e9ecef;*/
/*}*/

/*.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item.p-highlight {*/
/*  color: #ffffff;*/
/*  background: #007bff;*/
/*}*/

/*.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-empty-message {*/
/*  padding: 0.5rem 1.5rem;*/
/*  color: #212529;*/
/*  background: transparent;*/
/*}*/

/*.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item-group {*/
/*  margin: 0;*/
/*  padding: 0.75rem 1rem;*/
/*  color: #212529;*/
/*  background: #ffffff;*/
/*  font-weight: 600;*/
/*}*/

/*p-calendar.ng-dirty.ng-invalid > .p-calendar > .p-inputtext {*/
/*  border-color: #dc3545;*/
/*}*/

/*.p-datepicker {*/
/*  padding: 0;*/
/*  background: #ffffff;*/
/*  color: #212529;*/
/*  border: 1px solid #ced4da;*/
/*  border-radius: 4px;*/
/*}*/

/*.p-datepicker:not(.p-datepicker-inline) {*/
/*  background: #ffffff;*/
/*  border: 1px solid rgba(0, 0, 0, 0.15);*/
/*  box-shadow: none;*/
/*}*/

/*.p-datepicker:not(.p-datepicker-inline) .p-datepicker-header {*/
/*  background: #efefef;*/
/*}*/

/*.p-datepicker .p-datepicker-header {*/
/*  padding: 0.5rem;*/
/*  color: #212529;*/
/*  background: #ffffff;*/
/*  font-weight: 600;*/
/*  margin: 0;*/
/*  border-bottom: 1px solid #dee2e6;*/
/*  border-top-right-radius: 4px;*/
/*  border-top-left-radius: 4px;*/
/*}*/

/*.p-datepicker .p-datepicker-header .p-datepicker-prev,*/
/*.p-datepicker .p-datepicker-header .p-datepicker-next {*/
/*  width: 2rem;*/
/*  height: 2rem;*/
/*  color: #6c757d;*/
/*  border: 0 none;*/
/*  background: transparent;*/
/*  border-radius: 50%;*/
/*  transition: box-shadow 0.15s;*/
/*}*/

/*.p-datepicker .p-datepicker-header .p-datepicker-prev:enabled:hover,*/
/*.p-datepicker .p-datepicker-header .p-datepicker-next:enabled:hover {*/
/*  color: #495057;*/
/*  border-color: transparent;*/
/*  background: transparent;*/
/*}*/

/*.p-datepicker .p-datepicker-header .p-datepicker-prev:focus,*/
/*.p-datepicker .p-datepicker-header .p-datepicker-next:focus {*/
/*  outline: 0 none;*/
/*  outline-offset: 0;*/
/*  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);*/
/*}*/

/*.p-datepicker .p-datepicker-header .p-datepicker-title {*/
/*  line-height: 2rem;*/
/*}*/

/*.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-year,*/
/*.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month {*/
/*  color: #212529;*/
/*  transition: box-shadow 0.15s;*/
/*  font-weight: 600;*/
/*  padding: 0.5rem;*/
/*}*/

/*.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-year:enabled:hover,*/
/*.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month:enabled:hover {*/
/*  color: #007bff;*/
/*}*/

/*.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month {*/
/*  margin-right: 0.5rem;*/
/*}*/

/*.p-datepicker table {*/
/*  font-size: 1rem;*/
/*  margin: 0.5rem 0;*/
/*}*/

/*.p-datepicker table th {*/
/*  padding: 0.5rem;*/
/*}*/

/*.p-datepicker table th > span {*/
/*  width: 2.5rem;*/
/*  height: 2.5rem;*/
/*}*/

/*.p-datepicker table td {*/
/*  padding: 0.5rem;*/
/*}*/

/*.p-datepicker table td > span {*/
/*  width: 2.5rem;*/
/*  height: 2.5rem;*/
/*  border-radius: 4px;*/
/*  transition: box-shadow 0.15s;*/
/*  border: 1px solid transparent;*/
/*}*/

/*.p-datepicker table td > span.p-highlight {*/
/*  color: #ffffff;*/
/*  background: #007bff;*/
/*}*/

/*.p-datepicker table td > span:focus {*/
/*  outline: 0 none;*/
/*  outline-offset: 0;*/
/*  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);*/
/*}*/

/*.p-datepicker table td.p-datepicker-today > span {*/
/*  background: #ced4da;*/
/*  color: #212529;*/
/*  border-color: transparent;*/
/*}*/

/*.p-datepicker table td.p-datepicker-today > span.p-highlight {*/
/*  color: #ffffff;*/
/*  background: #007bff;*/
/*}*/

/*.p-datepicker .p-datepicker-buttonbar {*/
/*  padding: 1rem 0;*/
/*  border-top: 1px solid #dee2e6;*/
/*}*/

/*.p-datepicker .p-datepicker-buttonbar .p-button {*/
/*  width: auto;*/
/*}*/

/*.p-datepicker .p-timepicker {*/
/*  border-top: 1px solid #dee2e6;*/
/*  padding: 0.5rem;*/
/*}*/

/*.p-datepicker .p-timepicker button {*/
/*  width: 2rem;*/
/*  height: 2rem;*/
/*  color: #6c757d;*/
/*  border: 0 none;*/
/*  background: transparent;*/
/*  border-radius: 50%;*/
/*  transition: box-shadow 0.15s;*/
/*}*/

/*.p-datepicker .p-timepicker button:enabled:hover {*/
/*  color: #495057;*/
/*  border-color: transparent;*/
/*  background: transparent;*/
/*}*/

/*.p-datepicker .p-timepicker button:focus {*/
/*  outline: 0 none;*/
/*  outline-offset: 0;*/
/*  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);*/
/*}*/

/*.p-datepicker .p-timepicker button:last-child {*/
/*  margin-top: 0.2em;*/
/*}*/

/*.p-datepicker .p-timepicker span {*/
/*  font-size: 1.25rem;*/
/*}*/

/*.p-datepicker .p-timepicker > div {*/
/*  padding: 0 0.5rem;*/
/*}*/

/*.p-datepicker.p-datepicker-timeonly .p-timepicker {*/
/*  border-top: 0 none;*/
/*}*/

/*.p-datepicker .p-monthpicker {*/
/*  margin: 0.5rem 0;*/
/*}*/

/*.p-datepicker .p-monthpicker .p-monthpicker-month {*/
/*  padding: 0.5rem;*/
/*  transition: box-shadow 0.15s;*/
/*  border-radius: 4px;*/
/*}*/

/*.p-datepicker .p-monthpicker .p-monthpicker-month.p-highlight {*/
/*  color: #ffffff;*/
/*  background: #007bff;*/
/*}*/

/*.p-datepicker .p-yearpicker {*/
/*  margin: 0.5rem 0;*/
/*}*/

/*.p-datepicker .p-yearpicker .p-yearpicker-year {*/
/*  padding: 0.5rem;*/
/*  transition: box-shadow 0.15s;*/
/*  border-radius: 4px;*/
/*}*/

/*.p-datepicker .p-yearpicker .p-yearpicker-year.p-highlight {*/
/*  color: #ffffff;*/
/*  background: #007bff;*/
/*}*/

/*.p-datepicker.p-datepicker-multiple-month .p-datepicker-group {*/
/*  border-left: 1px solid #dee2e6;*/
/*  padding-right: 0;*/
/*  padding-left: 0;*/
/*  padding-top: 0;*/
/*  padding-bottom: 0;*/
/*}*/

/*.p-datepicker.p-datepicker-multiple-month .p-datepicker-group:first-child {*/
/*  padding-left: 0;*/
/*  border-left: 0 none;*/
/*}*/

/*.p-datepicker.p-datepicker-multiple-month .p-datepicker-group:last-child {*/
/*  padding-right: 0;*/
/*}*/

/*.p-datepicker:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):hover {*/
/*  background: #e9ecef;*/
/*}*/

/*.p-datepicker:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):focus {*/
/*  outline: 0 none;*/
/*  outline-offset: 0;*/
/*  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);*/
/*}*/

/*.p-datepicker:not(.p-disabled) .p-monthpicker .p-monthpicker-month:not(.p-disabled):not(.p-highlight):hover {*/
/*  background: #e9ecef;*/
/*}*/

/*.p-datepicker:not(.p-disabled) .p-monthpicker .p-monthpicker-month:not(.p-disabled):focus {*/
/*  outline: 0 none;*/
/*  outline-offset: 0;*/
/*  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);*/
/*}*/

/*.p-datepicker:not(.p-disabled) .p-yearpicker .p-yearpicker-year:not(.p-disabled):not(.p-highlight):hover {*/
/*  background: #e9ecef;*/
/*}*/

/*.p-datepicker:not(.p-disabled) .p-yearpicker .p-yearpicker-year:not(.p-disabled):focus {*/
/*  outline: 0 none;*/
/*  outline-offset: 0;*/
/*  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);*/
/*}*/

/*@media screen and (max-width: 769px) {*/
/*  .p-datepicker table th,*/
/*  .p-datepicker table td {*/
/*    padding: 0;*/
/*  }*/
/*}*/

/*.p-cascadeselect {*/
/*  background: #ffffff;*/
/*  border: 1px solid #ced4da;*/
/*  transition:*/
/*    background-color 0.15s,*/
/*    border-color 0.15s,*/
/*    box-shadow 0.15s;*/
/*  border-radius: 4px;*/
/*}*/

/*.p-cascadeselect:not(.p-disabled):hover {*/
/*  border-color: #ced4da;*/
/*}*/

/*.p-cascadeselect:not(.p-disabled).p-focus {*/
/*  outline: 0 none;*/
/*  outline-offset: 0;*/
/*  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);*/
/*  border-color: #007bff;*/
/*}*/

/*.p-cascadeselect .p-cascadeselect-label {*/
/*  background: transparent;*/
/*  border: 0 none;*/
/*  padding: 0.5rem 0.75rem;*/
/*}*/

/*.p-cascadeselect .p-cascadeselect-label.p-placeholder {*/
/*  color: #6c757d;*/
/*}*/

/*.p-cascadeselect .p-cascadeselect-label:enabled:focus {*/
/*  outline: 0 none;*/
/*  box-shadow: none;*/
/*}*/

/*.p-cascadeselect .p-cascadeselect-trigger {*/
/*  background: transparent;*/
/*  color: #495057;*/
/*  width: 2.357rem;*/
/*  border-top-right-radius: 4px;*/
/*  border-bottom-right-radius: 4px;*/
/*}*/

/*.p-cascadeselect-panel {*/
/*  background: #ffffff;*/
/*  color: #212529;*/
/*  border: 1px solid rgba(0, 0, 0, 0.15);*/
/*  border-radius: 4px;*/
/*  box-shadow: none;*/
/*}*/

/*.p-cascadeselect-panel .p-cascadeselect-items {*/
/*  padding: 0.5rem 0;*/
/*}*/

/*.p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item {*/
/*  margin: 0;*/
/*  border: 0 none;*/
/*  color: #212529;*/
/*  background: transparent;*/
/*  transition: box-shadow 0.15s;*/
/*  border-radius: 0;*/
/*}*/

/*.p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item .p-cascadeselect-item-content {*/
/*  padding: 0.5rem 1.5rem;*/
/*}*/

/*.p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item .p-cascadeselect-item-content:focus {*/
/*  outline: 0 none;*/
/*  outline-offset: 0;*/
/*  box-shadow: inset 0 0 0 0.15rem rgba(38, 143, 255, 0.5);*/
/*}*/

/*.p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item.p-highlight {*/
/*  color: #ffffff;*/
/*  background: #007bff;*/
/*}*/

/*.p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item:not(.p-highlight):not(.p-disabled):hover {*/
/*  color: #212529;*/
/*  background: #e9ecef;*/
/*}*/

/*.p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item .p-cascadeselect-group-icon {*/
/*  font-size: 0.875rem;*/
/*}*/

/*p-cascadeselect.ng-dirty.ng-invalid > .p-cascadeselect {*/
/*  border-color: #dc3545;*/
/*}*/

/*.p-input-filled .p-cascadeselect {*/
/*  background: #efefef;*/
/*}*/

/*.p-input-filled .p-cascadeselect:not(.p-disabled):hover {*/
/*  background-color: #efefef;*/
/*}*/

/*.p-input-filled .p-cascadeselect:not(.p-disabled).p-focus {*/
/*  background-color: #efefef;*/
/*}*/

/*.p-chips .p-chips-multiple-container {*/
/*  padding: 0.25rem 0.75rem;*/
/*}*/

/*.p-chips .p-chips-multiple-container:not(.p-disabled):hover {*/
/*  border-color: #ced4da;*/
/*}*/

/*.p-chips .p-chips-multiple-container:not(.p-disabled).p-focus {*/
/*  outline: 0 none;*/
/*  outline-offset: 0;*/
/*  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);*/
/*  border-color: #007bff;*/
/*}*/

/*.p-chips .p-chips-multiple-container .p-chips-token {*/
/*  padding: 0.25rem 0.75rem;*/
/*  margin-right: 0.5rem;*/
/*  background: #007bff;*/
/*  color: #ffffff;*/
/*  border-radius: 4px;*/
/*}*/

/*.p-chips .p-chips-multiple-container .p-chips-token .p-chips-token-icon {*/
/*  margin-left: 0.5rem;*/
/*}*/

/*.p-chips .p-chips-multiple-container .p-chips-input-token {*/
/*  padding: 0.25rem 0;*/
/*}*/

/*.p-chips .p-chips-multiple-container .p-chips-input-token input {*/
/*  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',*/
/*    'Segoe UI Symbol';*/
/*  font-size: 1rem;*/
/*  color: #212529;*/
/*  padding: 0;*/
/*  margin: 0;*/
/*}*/

/*p-chips.ng-dirty.ng-invalid > .p-chips > .p-inputtext {*/
/*  border-color: #dc3545;*/
/*}*/

/*.p-colorpicker-preview,*/
/*.p-fluid .p-colorpicker-preview.p-inputtext {*/
/*  width: 2rem;*/
/*  height: 2rem;*/
/*}*/

/*.p-colorpicker-panel {*/
/*  background: #212529;*/
/*  border: 1px solid #212529;*/
/*}*/

/*.p-colorpicker-panel .p-colorpicker-color-handle,*/
/*.p-colorpicker-panel .p-colorpicker-hue-handle {*/
/*  border-color: #ffffff;*/
/*}*/

/*.p-colorpicker-overlay-panel {*/
/*  box-shadow: none;*/
/*}*/

/*.p-input-filled .p-dropdown {*/
/*  background: #efefef;*/
/*}*/

/*.p-input-filled .p-dropdown:not(.p-disabled):hover {*/
/*  background-color: #efefef;*/
/*}*/

/*.p-input-filled .p-dropdown:not(.p-disabled).p-focus {*/
/*  background-color: #efefef;*/
/*}*/

/*.p-editor-container .p-editor-toolbar {*/
/*  background: #efefef;*/
/*  border-top-right-radius: 4px;*/
/*  border-top-left-radius: 4px;*/
/*}*/

/*.p-editor-container .p-editor-toolbar.ql-snow {*/
/*  border: 1px solid #dee2e6;*/
/*}*/

/*.p-editor-container .p-editor-toolbar.ql-snow .ql-stroke {*/
/*  stroke: #6c757d;*/
/*}*/

/*.p-editor-container .p-editor-toolbar.ql-snow .ql-fill {*/
/*  fill: #6c757d;*/
/*}*/

/*.p-editor-container .p-editor-toolbar.ql-snow .ql-picker .ql-picker-label {*/
/*  border: 0 none;*/
/*  color: #6c757d;*/
/*}*/

/*.p-editor-container .p-editor-toolbar.ql-snow .ql-picker .ql-picker-label:hover {*/
/*  color: #212529;*/
/*}*/

/*.p-editor-container .p-editor-toolbar.ql-snow .ql-picker .ql-picker-label:hover .ql-stroke {*/
/*  stroke: #212529;*/
/*}*/

/*.p-editor-container .p-editor-toolbar.ql-snow .ql-picker .ql-picker-label:hover .ql-fill {*/
/*  fill: #212529;*/
/*}*/

/*.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label {*/
/*  color: #212529;*/
/*}*/

/*.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-stroke {*/
/*  stroke: #212529;*/
/*}*/

/*.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-fill {*/
/*  fill: #212529;*/
/*}*/

/*.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {*/
/*  background: #ffffff;*/
/*  border: 1px solid rgba(0, 0, 0, 0.15);*/
/*  box-shadow: none;*/
/*  border-radius: 4px;*/
/*  padding: 0.5rem 0;*/
/*}*/

/*.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options .ql-picker-item {*/
/*  color: #212529;*/
/*}*/

/*.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options .ql-picker-item:hover {*/
/*  color: #212529;*/
/*  background: #e9ecef;*/
/*}*/

/*.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded:not(.ql-icon-picker) .ql-picker-item {*/
/*  padding: 0.5rem 1.5rem;*/
/*}*/

/*.p-editor-container .p-editor-content {*/
/*  border-bottom-right-radius: 4px;*/
/*  border-bottom-left-radius: 4px;*/
/*}*/

/*.p-editor-container .p-editor-content.ql-snow {*/
/*  border: 1px solid #dee2e6;*/
/*}*/

/*.p-editor-container .p-editor-content .ql-editor {*/
/*  background: #ffffff;*/
/*  color: #495057;*/
/*  border-bottom-right-radius: 4px;*/
/*  border-bottom-left-radius: 4px;*/
/*}*/

/*.p-editor-container .ql-snow.ql-toolbar button:hover,*/
/*.p-editor-container .ql-snow.ql-toolbar button:focus {*/
/*  color: #212529;*/
/*}*/

/*.p-editor-container .ql-snow.ql-toolbar button:hover .ql-stroke,*/
/*.p-editor-container .ql-snow.ql-toolbar button:focus .ql-stroke {*/
/*  stroke: #212529;*/
/*}*/

/*.p-editor-container .ql-snow.ql-toolbar button:hover .ql-fill,*/
/*.p-editor-container .ql-snow.ql-toolbar button:focus .ql-fill {*/
/*  fill: #212529;*/
/*}*/

/*.p-editor-container .ql-snow.ql-toolbar button.ql-active,*/
/*.p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active,*/
/*.p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected {*/
/*  color: #007bff;*/
/*}*/

/*.p-editor-container .ql-snow.ql-toolbar button.ql-active .ql-stroke,*/
/*.p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,*/
/*.p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke {*/
/*  stroke: #007bff;*/
/*}*/

/*.p-editor-container .ql-snow.ql-toolbar button.ql-active .ql-fill,*/
/*.p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-fill,*/
/*.p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-fill {*/
/*  fill: #007bff;*/
/*}*/

/*.p-editor-container .ql-snow.ql-toolbar button.ql-active .ql-picker-label,*/
/*.p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-picker-label,*/
/*.p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-picker-label {*/
/*  color: #007bff;*/
/*}*/

/*.p-inputgroup-addon {*/
/*  background: #e9ecef;*/
/*  color: #495057;*/
/*  border-top: 1px solid #ced4da;*/
/*  border-left: 1px solid #ced4da;*/
/*  border-bottom: 1px solid #ced4da;*/
/*  padding: 0.5rem 0.75rem;*/
/*  min-width: 2.357rem;*/
/*}*/

/*.p-inputgroup-addon:last-child {*/
/*  border-right: 1px solid #ced4da;*/
/*}*/

/*.p-inputgroup > .p-component,*/
/*.p-inputgroup > .p-element,*/
/*.p-inputgroup > .p-inputwrapper > .p-component > .p-inputtext,*/
/*.p-inputgroup > .p-float-label > .p-component {*/
/*  border-radius: 0;*/
/*  margin: 0;*/
/*}*/

/*.p-inputgroup > .p-component + .p-inputgroup-addon,*/
/*.p-inputgroup > .p-element + .p-inputgroup-addon,*/
/*.p-inputgroup > .p-inputwrapper > .p-component > .p-inputtext + .p-inputgroup-addon,*/
/*.p-inputgroup > .p-float-label > .p-component + .p-inputgroup-addon {*/
/*  border-left: 0 none;*/
/*}*/

/*.p-inputgroup > .p-component:focus,*/
/*.p-inputgroup > .p-element:focus,*/
/*.p-inputgroup > .p-inputwrapper > .p-component > .p-inputtext:focus,*/
/*.p-inputgroup > .p-float-label > .p-component:focus {*/
/*  z-index: 1;*/
/*}*/

/*.p-inputgroup > .p-component:focus ~ label,*/
/*.p-inputgroup > .p-element:focus ~ label,*/
/*.p-inputgroup > .p-inputwrapper > .p-component > .p-inputtext:focus ~ label,*/
/*.p-inputgroup > .p-float-label > .p-component:focus ~ label {*/
/*  z-index: 1;*/
/*}*/

/*.p-inputgroup-addon:first-child,*/
/*.p-inputgroup button:first-child,*/
/*.p-inputgroup input:first-child,*/
/*.p-inputgroup > .p-inputwrapper:first-child > .p-component,*/
/*.p-inputgroup > .p-inputwrapper:first-child > .p-component > .p-inputtext {*/
/*  border-top-left-radius: 4px;*/
/*  border-bottom-left-radius: 4px;*/
/*}*/

/*.p-inputgroup .p-float-label:first-child input {*/
/*  border-top-left-radius: 4px;*/
/*  border-bottom-left-radius: 4px;*/
/*}*/

/*.p-inputgroup-addon:last-child,*/
/*.p-inputgroup button:last-child,*/
/*.p-inputgroup input:last-child,*/
/*.p-inputgroup > .p-inputwrapper:last-child > .p-component,*/
/*.p-inputgroup > .p-inputwrapper:last-child > .p-component > .p-inputtext {*/
/*  border-top-right-radius: 4px;*/
/*  border-bottom-right-radius: 4px;*/
/*}*/

/*.p-inputgroup .p-float-label:last-child input {*/
/*  border-top-right-radius: 4px;*/
/*  border-bottom-right-radius: 4px;*/
/*}*/

/*.p-fluid .p-inputgroup .p-button {*/
/*  width: auto;*/
/*}*/

/*.p-fluid .p-inputgroup .p-button.p-button-icon-only {*/
/*  width: 2.357rem;*/
/*}*/

/*p-inputmask.ng-dirty.ng-invalid > .p-inputtext {*/
/*  border-color: #dc3545;*/
/*}*/

/*p-inputnumber.ng-dirty.ng-invalid > .p-inputnumber > .p-inputtext {*/
/*  border-color: #dc3545;*/
/*}*/

/*.p-float-label > label {*/
/*  left: 0.75rem;*/
/*  color: #6c757d;*/
/*  transition-duration: 0.15s;*/
/*}*/

/*.p-float-label > .ng-invalid.ng-dirty + label {*/
/*  color: #dc3545;*/
/*}*/

/*.p-input-icon-left.p-float-label > label {*/
/*  left: 2.5rem;*/
/*}*/

/*.p-input-icon-right > .p-inputtext {*/
/*  padding-right: 2.5rem;*/
/*}*/

/*::-webkit-input-placeholder {*/
/*  color: #6c757d;*/
/*}*/

/*:-moz-placeholder {*/
/*  color: #6c757d;*/
/*}*/

/*::-moz-placeholder {*/
/*  color: #6c757d;*/
/*}*/

/*:-ms-input-placeholder {*/
/*  color: #6c757d;*/
/*}*/

/*.p-input-filled .p-inputtext {*/
/*  background-color: #efefef;*/
/*}*/

/*.p-input-filled .p-inputtext:enabled:hover {*/
/*  background-color: #efefef;*/
/*}*/

/*.p-input-filled .p-inputtext:enabled:focus {*/
/*  background-color: #efefef;*/
/*}*/

/*.p-inputtext-sm .p-inputtext {*/
/*  font-size: 0.875rem;*/
/*  padding: 0.4375rem 0.65625rem;*/
/*}*/

/*.p-inputtext-lg .p-inputtext {*/
/*  font-size: 1.25rem;*/
/*  padding: 0.625rem 0.9375rem;*/
/*}*/

/*.p-listbox {*/
/*  background: #ffffff;*/
/*  color: #212529;*/
/*  border: 1px solid #ced4da;*/
/*  border-radius: 4px;*/
/*}*/

/*.p-listbox .p-listbox-header {*/
/*  padding: 0.75rem 1.5rem;*/
/*  border-bottom: 1px solid #dee2e6;*/
/*  color: #212529;*/
/*  background: #efefef;*/
/*  margin: 0;*/
/*  border-top-right-radius: 4px;*/
/*  border-top-left-radius: 4px;*/
/*}*/

/*.p-listbox .p-listbox-header .p-listbox-filter {*/
/*  padding-right: 1.75rem;*/
/*}*/

/*.p-listbox .p-listbox-header .p-listbox-filter-icon {*/
/*  right: 0.75rem;*/
/*  color: #495057;*/
/*}*/

/*.p-listbox .p-listbox-header .p-checkbox {*/
/*  margin-right: 0.5rem;*/
/*}*/

/*.p-listbox .p-listbox-list {*/
/*  padding: 0.5rem 0;*/
/*}*/

/*.p-listbox .p-listbox-list .p-listbox-item {*/
/*  margin: 0;*/
/*  padding: 0.5rem 1.5rem;*/
/*  border: 0 none;*/
/*  color: #212529;*/
/*  transition: box-shadow 0.15s;*/
/*  border-radius: 0;*/
/*}*/

/*.p-listbox .p-listbox-list .p-listbox-item.p-highlight {*/
/*  color: #ffffff;*/
/*  background: #007bff;*/
/*}*/

/*.p-listbox .p-listbox-list .p-listbox-item:focus {*/
/*  outline: 0 none;*/
/*  outline-offset: 0;*/
/*  box-shadow: inset 0 0 0 0.15rem rgba(38, 143, 255, 0.5);*/
/*}*/

/*.p-listbox .p-listbox-list .p-listbox-item .p-checkbox {*/
/*  margin-right: 0.5rem;*/
/*}*/

/*.p-listbox .p-listbox-list .p-listbox-item-group {*/
/*  margin: 0;*/
/*  padding: 0.75rem 1rem;*/
/*  color: #212529;*/
/*  background: #ffffff;*/
/*  font-weight: 600;*/
/*}*/

/*.p-listbox .p-listbox-list .p-listbox-empty-message {*/
/*  padding: 0.5rem 1.5rem;*/
/*  color: #212529;*/
/*  background: transparent;*/
/*}*/

/*.p-listbox:not(.p-disabled) .p-listbox-item:not(.p-highlight):not(.p-disabled):hover {*/
/*  color: #212529;*/
/*  background: #e9ecef;*/
/*}*/

/*p-listbox.ng-dirty.ng-invalid > .p-listbox {*/
/*  border-color: #dc3545;*/
/*}*/

/*.p-multiselect {*/
/*  background: #ffffff;*/
/*  border: 1px solid #ced4da;*/
/*  transition:*/
/*    background-color 0.15s,*/
/*    border-color 0.15s,*/
/*    box-shadow 0.15s;*/
/*  border-radius: 4px;*/
/*}*/

/*.p-multiselect:not(.p-disabled):hover {*/
/*  border-color: #ced4da;*/
/*}*/

/*.p-multiselect:not(.p-disabled).p-focus {*/
/*  outline: 0 none;*/
/*  outline-offset: 0;*/
/*  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);*/
/*  border-color: #007bff;*/
/*}*/

/*.p-multiselect .p-multiselect-label {*/
/*  padding: 0.5rem 0.75rem;*/
/*  transition:*/
/*    background-color 0.15s,*/
/*    border-color 0.15s,*/
/*    box-shadow 0.15s;*/
/*}*/

/*.p-multiselect .p-multiselect-label.p-placeholder {*/
/*  color: #6c757d;*/
/*}*/

/*.p-multiselect.p-multiselect-chip .p-multiselect-token {*/
/*  padding: 0.25rem 0.75rem;*/
/*  margin-right: 0.5rem;*/
/*  background: #007bff;*/
/*  color: #ffffff;*/
/*  border-radius: 4px;*/
/*}*/

/*.p-multiselect.p-multiselect-chip .p-multiselect-token .p-multiselect-token-icon {*/
/*  margin-left: 0.5rem;*/
/*}*/

/*.p-multiselect .p-multiselect-trigger {*/
/*  background: transparent;*/
/*  color: #495057;*/
/*  width: 2.357rem;*/
/*  border-top-right-radius: 4px;*/
/*  border-bottom-right-radius: 4px;*/
/*}*/

/*.p-inputwrapper-filled .p-multiselect.p-multiselect-chip .p-multiselect-label {*/
/*  padding: 0.25rem 0.75rem;*/
/*}*/

/*.p-multiselect-panel {*/
/*  background: #ffffff;*/
/*  color: #212529;*/
/*  border: 1px solid rgba(0, 0, 0, 0.15);*/
/*  border-radius: 4px;*/
/*  box-shadow: none;*/
/*}*/

/*.p-multiselect-panel .p-multiselect-header {*/
/*  padding: 0.75rem 1.5rem;*/
/*  border-bottom: 1px solid #dee2e6;*/
/*  color: #212529;*/
/*  background: #efefef;*/
/*  margin: 0;*/
/*  border-top-right-radius: 4px;*/
/*  border-top-left-radius: 4px;*/
/*}*/

/*.p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container .p-inputtext {*/
/*  padding-right: 1.75rem;*/
/*}*/

/*.p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container .p-multiselect-filter-icon {*/
/*  right: 0.75rem;*/
/*  color: #495057;*/
/*}*/

/*.p-multiselect-panel .p-multiselect-header .p-checkbox {*/
/*  margin-right: 0.5rem;*/
/*}*/

/*.p-multiselect-panel .p-multiselect-header .p-multiselect-close {*/
/*  margin-left: 0.5rem;*/
/*  width: 2rem;*/
/*  height: 2rem;*/
/*  color: #6c757d;*/
/*  border: 0 none;*/
/*  background: transparent;*/
/*  border-radius: 50%;*/
/*  transition: box-shadow 0.15s;*/
/*}*/

/*.p-multiselect-panel .p-multiselect-header .p-multiselect-close:enabled:hover {*/
/*  color: #495057;*/
/*  border-color: transparent;*/
/*  background: transparent;*/
/*}*/

/*.p-multiselect-panel .p-multiselect-header .p-multiselect-close:focus {*/
/*  outline: 0 none;*/
/*  outline-offset: 0;*/
/*  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);*/
/*}*/

/*.p-multiselect-panel .p-multiselect-items:not(.p-multiselect-virtualscroll) {*/
/*  padding: 0.5rem 0;*/
/*}*/

/*.p-multiselect-panel .p-multiselect-items.p-multiselect-virtualscroll .cdk-virtual-scroll-content-wrapper {*/
/*  padding: 0.5rem 0;*/
/*}*/

/*.p-multiselect-panel .p-multiselect-items .p-multiselect-item {*/
/*  margin: 0;*/
/*  padding: 0.5rem 1.5rem;*/
/*  border: 0 none;*/
/*  color: #212529;*/
/*  background: transparent;*/
/*  transition: box-shadow 0.15s;*/
/*  border-radius: 0;*/
/*}*/

/*.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {*/
/*  color: #ffffff;*/
/*  background: #007bff;*/
/*}*/

/*.p-multiselect-panel .p-multiselect-items .p-multiselect-item:not(.p-highlight):not(.p-disabled):hover {*/
/*  color: #212529;*/
/*  background: #e9ecef;*/
/*}*/

/*.p-multiselect-panel .p-multiselect-items .p-multiselect-item:focus {*/
/*  outline: 0 none;*/
/*  outline-offset: 0;*/
/*  box-shadow: inset 0 0 0 0.15rem rgba(38, 143, 255, 0.5);*/
/*}*/

/*.p-multiselect-panel .p-multiselect-items .p-multiselect-item .p-checkbox {*/
/*  margin-right: 0.5rem;*/
/*}*/

/*.p-multiselect-panel .p-multiselect-items .p-multiselect-item-group {*/
/*  margin: 0;*/
/*  padding: 0.75rem 1rem;*/
/*  color: #212529;*/
/*  background: #ffffff;*/
/*  font-weight: 600;*/
/*}*/

/*.p-multiselect-panel .p-multiselect-items .p-multiselect-empty-message {*/
/*  padding: 0.5rem 1.5rem;*/
/*  color: #212529;*/
/*  background: transparent;*/
/*}*/

/*p-multiselect.ng-dirty.ng-invalid > .p-multiselect {*/
/*  border-color: #dc3545;*/
/*}*/

/*.p-input-filled .p-multiselect {*/
/*  background: #efefef;*/
/*}*/

/*.p-input-filled .p-multiselect:not(.p-disabled):hover {*/
/*  background-color: #efefef;*/
/*}*/

/*.p-input-filled .p-multiselect:not(.p-disabled).p-focus {*/
/*  background-color: #efefef;*/
/*}*/

/*p-password.ng-invalid.ng-dirty > .p-password > .p-inputtext {*/
/*  border-color: #dc3545;*/
/*}*/

/*.p-password-panel {*/
/*  padding: 1.25rem;*/
/*  background: #ffffff;*/
/*  color: #212529;*/
/*  border: 1px solid rgba(0, 0, 0, 0.2);*/
/*  box-shadow: none;*/
/*  border-radius: 4px;*/
/*}*/

/*.p-password-panel .p-password-meter {*/
/*  margin-bottom: 0.5rem;*/
/*  background: #e9ecef;*/
/*}*/

/*.p-password-panel .p-password-meter .p-password-strength.weak {*/
/*  background: #dc3545;*/
/*}*/

/*.p-password-panel .p-password-meter .p-password-strength.medium {*/
/*  background: #ffc107;*/
/*}*/

/*.p-password-panel .p-password-meter .p-password-strength.strong {*/
/*  background: #28a745;*/
/*}*/

/*.p-input-filled .p-radiobutton .p-radiobutton-box {*/
/*  background-color: #efefef;*/
/*}*/

/*.p-input-filled .p-radiobutton .p-radiobutton-box:not(.p-disabled):hover {*/
/*  background-color: #efefef;*/
/*}*/

/*.p-input-filled .p-radiobutton .p-radiobutton-box.p-highlight {*/
/*  background: #007bff;*/
/*}*/

/*.p-input-filled .p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover {*/
/*  background: #0062cc;*/
/*}*/

/*.p-radiobutton-label {*/
/*  margin-left: 0.5rem;*/
/*}*/

/*.p-highlight .p-radiobutton .p-radiobutton-box {*/
/*  border-color: #ffffff;*/
/*}*/

/*.p-rating .p-rating-icon {*/
/*  color: #495057;*/
/*  margin-left: 0.5rem;*/
/*  transition:*/
/*    background-color 0.15s,*/
/*    border-color 0.15s,*/
/*    box-shadow 0.15s;*/
/*  font-size: 1.143rem;*/
/*}*/

/*.p-rating .p-rating-icon.p-rating-cancel {*/
/*  color: #dc3545;*/
/*}*/

/*.p-rating .p-rating-icon:focus {*/
/*  outline: 0 none;*/
/*  outline-offset: 0;*/
/*  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);*/
/*}*/

/*.p-rating .p-rating-icon:first-child {*/
/*  margin-left: 0;*/
/*}*/

/*.p-rating .p-rating-icon.pi-star-fill {*/
/*  color: #007bff;*/
/*}*/

/*.p-rating:not(.p-disabled):not(.p-readonly) .p-rating-icon:hover {*/
/*  color: #007bff;*/
/*}*/

/*.p-rating:not(.p-disabled):not(.p-readonly) .p-rating-icon.p-rating-cancel:hover {*/
/*  color: #dc3545;*/
/*}*/

/*.p-highlight .p-rating .p-rating-icon {*/
/*  color: #ffffff;*/
/*}*/

/*.p-slider {*/
/*  background: #e9ecef;*/
/*  border: 0 none;*/
/*  border-radius: 4px;*/
/*}*/

/*.p-slider.p-slider-horizontal {*/
/*  height: 0.286rem;*/
/*}*/

/*.p-slider.p-slider-horizontal .p-slider-handle {*/
/*  margin-top: -0.5715rem;*/
/*  margin-left: -0.5715rem;*/
/*}*/

/*.p-slider.p-slider-vertical {*/
/*  width: 0.286rem;*/
/*}*/

/*.p-slider.p-slider-vertical .p-slider-handle {*/
/*  margin-left: -0.5715rem;*/
/*  margin-bottom: -0.5715rem;*/
/*}*/

/*.p-slider .p-slider-handle {*/
/*  height: 1.143rem;*/
/*  width: 1.143rem;*/
/*  background: #007bff;*/
/*  border: 2px solid #007bff;*/
/*  border-radius: 4px;*/
/*  transition:*/
/*    background-color 0.15s,*/
/*    border-color 0.15s,*/
/*    box-shadow 0.15s;*/
/*}*/

/*.p-slider .p-slider-handle:focus {*/
/*  outline: 0 none;*/
/*  outline-offset: 0;*/
/*  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);*/
/*}*/

/*.p-slider .p-slider-range {*/
/*  background: #007bff;*/
/*}*/

/*.p-slider:not(.p-disabled) .p-slider-handle:hover {*/
/*  background: #0069d9;*/
/*  border-color: #0069d9;*/
/*}*/

/*.p-slider.p-slider-animate.p-slider-horizontal .p-slider-handle {*/
/*  transition:*/
/*    background-color 0.15s,*/
/*    border-color 0.15s,*/
/*    box-shadow 0.15s,*/
/*    left 0.15s;*/
/*}*/

/*.p-slider.p-slider-animate.p-slider-horizontal .p-slider-range {*/
/*  transition: width 0.15s;*/
/*}*/

/*.p-slider.p-slider-animate.p-slider-vertical .p-slider-handle {*/
/*  transition:*/
/*    background-color 0.15s,*/
/*    border-color 0.15s,*/
/*    box-shadow 0.15s,*/
/*    bottom 0.15s;*/
/*}*/

/*.p-slider.p-slider-animate.p-slider-vertical .p-slider-range {*/
/*  transition: height 0.15s;*/
/*}*/

/*.p-togglebutton.p-button {*/
/*  background: #6c757d;*/
/*  border: 1px solid #6c757d;*/
/*  color: #ffffff;*/
/*  transition:*/
/*    background-color 0.15s,*/
/*    border-color 0.15s,*/
/*    box-shadow 0.15s;*/
/*}*/

/*.p-togglebutton.p-button .p-button-icon-left,*/
/*.p-togglebutton.p-button .p-button-icon-right {*/
/*  color: #ffffff;*/
/*}*/

/*.p-togglebutton.p-button:not(.p-disabled):not(.p-highlight):hover {*/
/*  background: #5a6268;*/
/*  border-color: #545b62;*/
/*  color: #ffffff;*/
/*}*/

/*.p-togglebutton.p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-left,*/
/*.p-togglebutton.p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-right {*/
/*  color: #ffffff;*/
/*}*/

/*.p-togglebutton.p-button.p-highlight {*/
/*  background: #545b62;*/
/*  border-color: #4e555b;*/
/*  color: #ffffff;*/
/*}*/

/*.p-togglebutton.p-button.p-highlight .p-button-icon-left,*/
/*.p-togglebutton.p-button.p-highlight .p-button-icon-right {*/
/*  color: #ffffff;*/
/*}*/

/*.p-togglebutton.p-button.p-highlight:hover {*/
/*  background: #545b62;*/
/*  border-color: #4e555b;*/
/*  color: #ffffff;*/
/*}*/

/*.p-togglebutton.p-button.p-highlight:hover .p-button-icon-left,*/
/*.p-togglebutton.p-button.p-highlight:hover .p-button-icon-right {*/
/*  color: #ffffff;*/
/*}*/

/*p-togglebutton.ng-dirty.ng-invalid > .p-togglebutton.p-button {*/
/*  border-color: #dc3545;*/
/*}*/

/*.p-treeselect {*/
/*  background: #ffffff;*/
/*  border: 1px solid #ced4da;*/
/*  transition:*/
/*    background-color 0.15s,*/
/*    border-color 0.15s,*/
/*    box-shadow 0.15s;*/
/*  border-radius: 4px;*/
/*}*/

/*.p-treeselect:not(.p-disabled):hover {*/
/*  border-color: #ced4da;*/
/*}*/

/*.p-treeselect:not(.p-disabled).p-focus {*/
/*  outline: 0 none;*/
/*  outline-offset: 0;*/
/*  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);*/
/*  border-color: #007bff;*/
/*}*/

/*.p-treeselect .p-treeselect-label {*/
/*  padding: 0.5rem 0.75rem;*/
/*  transition:*/
/*    background-color 0.15s,*/
/*    border-color 0.15s,*/
/*    box-shadow 0.15s;*/
/*}*/

/*.p-treeselect .p-treeselect-label.p-placeholder {*/
/*  color: #6c757d;*/
/*}*/

/*.p-treeselect.p-treeselect-chip .p-treeselect-token {*/
/*  padding: 0.25rem 0.75rem;*/
/*  margin-right: 0.5rem;*/
/*  background: #dee2e6;*/
/*  color: #212529;*/
/*  border-radius: 16px;*/
/*}*/

/*.p-treeselect .p-treeselect-trigger {*/
/*  background: transparent;*/
/*  color: #495057;*/
/*  width: 2.357rem;*/
/*  border-top-right-radius: 4px;*/
/*  border-bottom-right-radius: 4px;*/
/*}*/

/*p-treeselect.ng-invalid.ng-dirty > .p-treeselect {*/
/*  border-color: #dc3545;*/
/*}*/

/*.p-inputwrapper-filled .p-treeselect.p-treeselect-chip .p-treeselect-label {*/
/*  padding: 0.25rem 0.75rem;*/
/*}*/

/*.p-treeselect-panel {*/
/*  background: #ffffff;*/
/*  color: #212529;*/
/*  border: 1px solid rgba(0, 0, 0, 0.15);*/
/*  border-radius: 4px;*/
/*  box-shadow: none;*/
/*}*/

/*.p-treeselect-panel .p-treeselect-items-wrapper .p-tree {*/
/*  border: 0 none;*/
/*}*/

/*.p-treeselect-panel .p-treeselect-items-wrapper .p-treeselect-empty-message {*/
/*  padding: 0.5rem 1.5rem;*/
/*  color: #212529;*/
/*  background: transparent;*/
/*}*/

/*.p-input-filled .p-treeselect {*/
/*  background: #efefef;*/
/*}*/

/*.p-input-filled .p-treeselect:not(.p-disabled):hover {*/
/*  background-color: #efefef;*/
/*}*/

/*.p-input-filled .p-treeselect:not(.p-disabled).p-focus {*/
/*  background-color: #efefef;*/
/*}*/

/*.p-fluid .p-button {*/
/*  width: 100%;*/
/*}*/

/*.p-fluid .p-button-icon-only {*/
/*  width: 2.357rem;*/
/*}*/

/*.p-fluid .p-buttonset {*/
/*  display: flex;*/
/*}*/

/*.p-fluid .p-buttonset .p-button {*/
/*  flex: 1;*/
/*}*/

/*.p-speeddial-button.p-button.p-button-icon-only {*/
/*  width: 4rem;*/
/*  height: 4rem;*/
/*}*/

/*.p-speeddial-button.p-button.p-button-icon-only .p-button-icon {*/
/*  font-size: 1.3rem;*/
/*}*/

/*.p-speeddial-action {*/
/*  width: 3rem;*/
/*  height: 3rem;*/
/*  background: #495057;*/
/*  color: #fff;*/
/*}*/

/*.p-speeddial-action:hover {*/
/*  background: #343a40;*/
/*  color: #fff;*/
/*}*/

/*.p-speeddial-direction-up .p-speeddial-item {*/
/*  margin: 0.25rem 0;*/
/*}*/

/*.p-speeddial-direction-up .p-speeddial-item:first-child {*/
/*  margin-bottom: 0.5rem;*/
/*}*/

/*.p-speeddial-direction-down .p-speeddial-item {*/
/*  margin: 0.25rem 0;*/
/*}*/

/*.p-speeddial-direction-down .p-speeddial-item:first-child {*/
/*  margin-top: 0.5rem;*/
/*}*/

/*.p-speeddial-direction-left .p-speeddial-item {*/
/*  margin: 0 0.25rem;*/
/*}*/

/*.p-speeddial-direction-left .p-speeddial-item:first-child {*/
/*  margin-right: 0.5rem;*/
/*}*/

/*.p-speeddial-direction-right .p-speeddial-item {*/
/*  margin: 0 0.25rem;*/
/*}*/

/*.p-speeddial-direction-right .p-speeddial-item:first-child {*/
/*  margin-left: 0.5rem;*/
/*}*/

/*.p-speeddial-circle .p-speeddial-item,*/
/*.p-speeddial-semi-circle .p-speeddial-item,*/
/*.p-speeddial-quarter-circle .p-speeddial-item {*/
/*  margin: 0;*/
/*}*/

/*.p-speeddial-circle .p-speeddial-item:first-child,*/
/*.p-speeddial-circle .p-speeddial-item:last-child,*/
/*.p-speeddial-semi-circle .p-speeddial-item:first-child,*/
/*.p-speeddial-semi-circle .p-speeddial-item:last-child,*/
/*.p-speeddial-quarter-circle .p-speeddial-item:first-child,*/
/*.p-speeddial-quarter-circle .p-speeddial-item:last-child {*/
/*  margin: 0;*/
/*}*/

/*.p-speeddial-mask {*/
/*  background-color: rgba(0, 0, 0, 0.4);*/
/*}*/

/*.p-carousel .p-carousel-content .p-carousel-prev,*/
/*.p-carousel .p-carousel-content .p-carousel-next {*/
/*  width: 2rem;*/
/*  height: 2rem;*/
/*  color: #6c757d;*/
/*  border: 0 none;*/
/*  background: transparent;*/
/*  border-radius: 50%;*/
/*  transition: box-shadow 0.15s;*/
/*  margin: 0.5rem;*/
/*}*/

/*.p-carousel .p-carousel-content .p-carousel-prev:enabled:hover,*/
/*.p-carousel .p-carousel-content .p-carousel-next:enabled:hover {*/
/*  color: #495057;*/
/*  border-color: transparent;*/
/*  background: transparent;*/
/*}*/

/*.p-carousel .p-carousel-content .p-carousel-prev:focus,*/
/*.p-carousel .p-carousel-content .p-carousel-next:focus {*/
/*  outline: 0 none;*/
/*  outline-offset: 0;*/
/*  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);*/
/*}*/

/*.p-carousel .p-carousel-indicators {*/
/*  padding: 1rem;*/
/*}*/

/*.p-carousel .p-carousel-indicators .p-carousel-indicator {*/
/*  margin-right: 0.5rem;*/
/*  margin-bottom: 0.5rem;*/
/*}*/

/*.p-carousel .p-carousel-indicators .p-carousel-indicator button {*/
/*  background-color: #e9ecef;*/
/*  width: 2rem;*/
/*  height: 0.5rem;*/
/*  transition: box-shadow 0.15s;*/
/*  border-radius: 0;*/
/*}*/

/*.p-carousel .p-carousel-indicators .p-carousel-indicator button:hover {*/
/*  background: #dee2e6;*/
/*}*/

/*.p-carousel .p-carousel-indicators .p-carousel-indicator.p-highlight button {*/
/*  background: #007bff;*/
/*  color: #ffffff;*/
/*}*/

/*.p-dataview .p-paginator-top {*/
/*  border-width: 1px 0 0 0;*/
/*  border-radius: 0;*/
/*}*/

/*.p-dataview .p-paginator-bottom {*/
/*  border-width: 1px 0 0 0;*/
/*  border-radius: 0;*/
/*}*/

/*.p-dataview .p-dataview-header {*/
/*  background: #efefef;*/
/*  color: #212529;*/
/*  border: solid #dee2e6;*/
/*  border-width: 1px 0 0 0;*/
/*  padding: 1rem 1rem;*/
/*  font-weight: 600;*/
/*}*/

/*.p-dataview .p-dataview-content {*/
/*  background: #ffffff;*/
/*  color: #212529;*/
/*  border: 0 none;*/
/*  padding: 0;*/
/*}*/

/*.p-dataview.p-dataview-list .p-dataview-content > .p-grid > div {*/
/*  border: 1px solid #dee2e6;*/
/*  border-width: 1px 0 0 0;*/
/*}*/

/*.p-dataview .p-dataview-footer {*/
/*  background: #efefef;*/
/*  color: #212529;*/
/*  border: 1px solid #dee2e6;*/
/*  border-width: 1px 0 1px 0;*/
/*  padding: 1rem 1rem;*/
/*  font-weight: 600;*/
/*  border-bottom-left-radius: 4px;*/
/*  border-bottom-right-radius: 4px;*/
/*}*/

/*.p-dataview .p-dataview-loading-icon {*/
/*  font-size: 2rem;*/
/*}*/

/*.p-dataview .p-dataview-emptymessage {*/
/*  padding: 1.25rem;*/
/*}*/

/*.p-column-filter-row .p-column-filter-menu-button,*/
/*.p-column-filter-row .p-column-filter-clear-button {*/
/*  margin-left: 0.5rem;*/
/*}*/

/*.p-column-filter-menu-button {*/
/*  width: 2rem;*/
/*  height: 2rem;*/
/*  color: #6c757d;*/
/*  border: 0 none;*/
/*  background: transparent;*/
/*  border-radius: 50%;*/
/*  transition: box-shadow 0.15s;*/
/*}*/

/*.p-column-filter-menu-button:hover {*/
/*  color: #495057;*/
/*  border-color: transparent;*/
/*  background: transparent;*/
/*}*/

/*.p-column-filter-menu-button.p-column-filter-menu-button-open,*/
/*.p-column-filter-menu-button.p-column-filter-menu-button-open:hover {*/
/*  background: transparent;*/
/*  color: #495057;*/
/*}*/

/*.p-column-filter-menu-button.p-column-filter-menu-button-active,*/
/*.p-column-filter-menu-button.p-column-filter-menu-button-active:hover {*/
/*  background: #007bff;*/
/*  color: #ffffff;*/
/*}*/

/*.p-column-filter-menu-button:focus {*/
/*  outline: 0 none;*/
/*  outline-offset: 0;*/
/*  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);*/
/*}*/

/*.p-column-filter-clear-button {*/
/*  width: 2rem;*/
/*  height: 2rem;*/
/*  color: #6c757d;*/
/*  border: 0 none;*/
/*  background: transparent;*/
/*  border-radius: 50%;*/
/*  transition: box-shadow 0.15s;*/
/*}*/

/*.p-column-filter-clear-button:hover {*/
/*  color: #495057;*/
/*  border-color: transparent;*/
/*  background: transparent;*/
/*}*/

/*.p-column-filter-clear-button:focus {*/
/*  outline: 0 none;*/
/*  outline-offset: 0;*/
/*  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);*/
/*}*/

/*.p-column-filter-overlay {*/
/*  background: #ffffff;*/
/*  color: #212529;*/
/*  border: 1px solid rgba(0, 0, 0, 0.15);*/
/*  border-radius: 4px;*/
/*  box-shadow: none;*/
/*  min-width: 12.5rem;*/
/*}*/

/*.p-column-filter-overlay .p-column-filter-row-items {*/
/*  padding: 0.5rem 0;*/
/*}*/

/*.p-column-filter-overlay .p-column-filter-row-items .p-column-filter-row-item {*/
/*  margin: 0;*/
/*  padding: 0.5rem 1.5rem;*/
/*  border: 0 none;*/
/*  color: #212529;*/
/*  background: transparent;*/
/*  transition: box-shadow 0.15s;*/
/*  border-radius: 0;*/
/*}*/

/*.p-column-filter-overlay .p-column-filter-row-items .p-column-filter-row-item.p-highlight {*/
/*  color: #ffffff;*/
/*  background: #007bff;*/
/*}*/

/*.p-column-filter-overlay .p-column-filter-row-items .p-column-filter-row-item:not(.p-highlight):not(.p-disabled):hover {*/
/*  color: #212529;*/
/*  background: #e9ecef;*/
/*}*/

/*.p-column-filter-overlay .p-column-filter-row-items .p-column-filter-row-item:focus {*/
/*  outline: 0 none;*/
/*  outline-offset: 0;*/
/*  box-shadow: inset 0 0 0 0.15rem rgba(38, 143, 255, 0.5);*/
/*}*/

/*.p-column-filter-overlay .p-column-filter-row-items .p-column-filter-separator {*/
/*  border-top: 1px solid #dee2e6;*/
/*  margin: 0.5rem 0;*/
/*}*/

/*.p-column-filter-overlay-menu .p-column-filter-operator {*/
/*  padding: 0.75rem 1.5rem;*/
/*  border-bottom: 1px solid #dee2e6;*/
/*  color: #212529;*/
/*  background: #efefef;*/
/*  margin: 0;*/
/*  border-top-right-radius: 4px;*/
/*  border-top-left-radius: 4px;*/
/*}*/

/*.p-column-filter-overlay-menu .p-column-filter-constraint {*/
/*  padding: 1.25rem;*/
/*  border-bottom: 1px solid #dee2e6;*/
/*}*/

/*.p-column-filter-overlay-menu .p-column-filter-constraint .p-column-filter-matchmode-dropdown {*/
/*  margin-bottom: 0.5rem;*/
/*}*/

/*.p-column-filter-overlay-menu .p-column-filter-constraint .p-column-filter-remove-button {*/
/*  margin-top: 0.5rem;*/
/*}*/

/*.p-column-filter-overlay-menu .p-column-filter-constraint:last-child {*/
/*  border-bottom: 0 none;*/
/*}*/

/*.p-column-filter-overlay-menu .p-column-filter-add-rule {*/
/*  padding: 0.5rem 1.25rem;*/
/*}*/

/*.p-column-filter-overlay-menu .p-column-filter-buttonbar {*/
/*  padding: 1.25rem;*/
/*}*/

/*.p-orderlist .p-orderlist-controls {*/
/*  padding: 1.25rem;*/
/*}*/

/*.p-orderlist .p-orderlist-controls .p-button {*/
/*  margin-bottom: 0.5rem;*/
/*}*/

/*.p-orderlist .p-orderlist-header {*/
/*  background: #efefef;*/
/*  color: #212529;*/
/*  border: 1px solid #dee2e6;*/
/*  padding: 1rem 1.25rem;*/
/*  border-bottom: 0 none;*/
/*  border-top-right-radius: 4px;*/
/*  border-top-left-radius: 4px;*/
/*}*/

/*.p-orderlist .p-orderlist-header .p-orderlist-title {*/
/*  font-weight: 600;*/
/*}*/

/*.p-orderlist .p-orderlist-filter-container {*/
/*  padding: 1rem 1.25rem;*/
/*  background: #ffffff;*/
/*  border: 1px solid #dee2e6;*/
/*  border-bottom: 0 none;*/
/*}*/

/*.p-orderlist .p-orderlist-filter-container .p-orderlist-filter-input {*/
/*  padding-right: 1.75rem;*/
/*}*/

/*.p-orderlist .p-orderlist-filter-container .p-orderlist-filter-icon {*/
/*  right: 0.75rem;*/
/*  color: #495057;*/
/*}*/

/*.p-orderlist .p-orderlist-list {*/
/*  border: 1px solid #dee2e6;*/
/*  background: #ffffff;*/
/*  color: #212529;*/
/*  padding: 0.5rem 0;*/
/*  border-bottom-right-radius: 4px;*/
/*  border-bottom-left-radius: 4px;*/
/*}*/

/*.p-orderlist .p-orderlist-list .p-orderlist-item {*/
/*  padding: 0.5rem 1.5rem;*/
/*  margin: 0;*/
/*  border: 0 none;*/
/*  color: #212529;*/
/*  background: transparent;*/
/*  transition: box-shadow 0.15s;*/
/*}*/

/*.p-orderlist .p-orderlist-list .p-orderlist-item:focus {*/
/*  outline: 0 none;*/
/*  outline-offset: 0;*/
/*  box-shadow: inset 0 0 0 0.15rem rgba(38, 143, 255, 0.5);*/
/*}*/

/*.p-orderlist .p-orderlist-list .p-orderlist-item.p-highlight {*/
/*  color: #ffffff;*/
/*  background: #007bff;*/
/*}*/

/*.p-orderlist .p-orderlist-list .p-orderlist-empty-message {*/
/*  padding: 0.5rem 1.5rem;*/
/*  color: #212529;*/
/*}*/

/*.p-orderlist .p-orderlist-list:not(.cdk-drop-list-dragging) .p-orderlist-item:not(.p-highlight):hover {*/
/*  background: #e9ecef;*/
/*  color: #212529;*/
/*}*/

/*.p-orderlist-item.cdk-drag-preview {*/
/*  padding: 0.5rem 1.5rem;*/
/*  box-shadow: none;*/
/*  border: 0 none;*/
/*  color: #212529;*/
/*  background: #ffffff;*/
/*  margin: 0;*/
/*}*/

/*.p-organizationchart .p-organizationchart-node-content.p-organizationchart-selectable-node:not(.p-highlight):hover {*/
/*  background: #e9ecef;*/
/*  color: #212529;*/
/*}*/

/*.p-organizationchart .p-organizationchart-node-content.p-highlight {*/
/*  background: #007bff;*/
/*  color: #ffffff;*/
/*}*/

/*.p-organizationchart .p-organizationchart-node-content.p-highlight .p-node-toggler i {*/
/*  color: #003e80;*/
/*}*/

/*.p-organizationchart .p-organizationchart-line-down {*/
/*  background: #dee2e6;*/
/*}*/

/*.p-organizationchart .p-organizationchart-line-left {*/
/*  border-right: 1px solid #dee2e6;*/
/*  border-color: #dee2e6;*/
/*}*/

/*.p-organizationchart .p-organizationchart-line-top {*/
/*  border-top: 1px solid #dee2e6;*/
/*  border-color: #dee2e6;*/
/*}*/

/*.p-organizationchart .p-organizationchart-node-content {*/
/*  border: 1px solid #dee2e6;*/
/*  background: #ffffff;*/
/*  color: #212529;*/
/*  padding: 1.25rem;*/
/*}*/

/*.p-organizationchart .p-organizationchart-node-content .p-node-toggler {*/
/*  background: inherit;*/
/*  color: inherit;*/
/*  border-radius: 50%;*/
/*}*/

/*.p-organizationchart .p-organizationchart-node-content .p-node-toggler:focus {*/
/*  outline: 0 none;*/
/*  outline-offset: 0;*/
/*  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);*/
/*}*/

/*.p-paginator {*/
/*  padding: 0.75rem;*/
/*}*/

/*.p-paginator .p-paginator-first,*/
/*.p-paginator .p-paginator-prev,*/
/*.p-paginator .p-paginator-next,*/
/*.p-paginator .p-paginator-last {*/
/*  min-width: 2.357rem;*/
/*  height: 2.357rem;*/
/*  margin: 0 0 0 -1px;*/
/*  transition: box-shadow 0.15s;*/
/*  border-radius: 0;*/
/*}*/

/*.p-paginator .p-paginator-first {*/
/*  border-top-left-radius: 0;*/
/*  border-bottom-left-radius: 0;*/
/*}*/

/*.p-paginator .p-paginator-last {*/
/*  border-top-right-radius: 0;*/
/*  border-bottom-right-radius: 0;*/
/*}*/

/*.p-paginator .p-dropdown {*/
/*  margin-left: 0.5rem;*/
/*  margin-right: 0.5rem;*/
/*  height: 2.357rem;*/
/*}*/

/*.p-paginator .p-dropdown .p-dropdown-label {*/
/*  padding-right: 0;*/
/*}*/

/*.p-paginator .p-paginator-page-input {*/
/*  margin-left: 0.5rem;*/
/*  margin-right: 0.5rem;*/
/*}*/

/*.p-paginator .p-paginator-page-input .p-inputtext {*/
/*  max-width: 2.357rem;*/
/*}*/

/*.p-paginator .p-paginator-current {*/
/*  min-width: 2.357rem;*/
/*  height: 2.357rem;*/
/*  margin: 0 0 0 -1px;*/
/*  padding: 0 0.5rem;*/
/*}*/

/*.p-paginator .p-paginator-pages .p-paginator-page {*/
/*  min-width: 2.357rem;*/
/*  height: 2.357rem;*/
/*  margin: 0 0 0 -1px;*/
/*  transition: box-shadow 0.15s;*/
/*  border-radius: 0;*/
/*}*/

/*.p-picklist .p-picklist-buttons {*/
/*  padding: 1.25rem;*/
/*}*/

/*.p-picklist .p-picklist-buttons .p-button {*/
/*  margin-bottom: 0.5rem;*/
/*}*/

/*.p-picklist .p-picklist-header {*/
/*  background: #efefef;*/
/*  color: #212529;*/
/*  border: 1px solid #dee2e6;*/
/*  padding: 1rem 1.25rem;*/
/*  border-bottom: 0 none;*/
/*  border-top-right-radius: 4px;*/
/*  border-top-left-radius: 4px;*/
/*}*/

/*.p-picklist .p-picklist-header .p-picklist-title {*/
/*  font-weight: 600;*/
/*}*/

/*.p-picklist .p-picklist-filter-container {*/
/*  padding: 1rem 1.25rem;*/
/*  background: #ffffff;*/
/*  border: 1px solid #dee2e6;*/
/*  border-bottom: 0 none;*/
/*}*/

/*.p-picklist .p-picklist-filter-container .p-picklist-filter-input {*/
/*  padding-right: 1.75rem;*/
/*}*/

/*.p-picklist .p-picklist-filter-container .p-picklist-filter-icon {*/
/*  right: 0.75rem;*/
/*  color: #495057;*/
/*}*/

/*.p-picklist .p-picklist-list {*/
/*  border: 1px solid #dee2e6;*/
/*  background: #ffffff;*/
/*  color: #212529;*/
/*  padding: 0.5rem 0;*/
/*  border-bottom-right-radius: 4px;*/
/*  border-bottom-left-radius: 4px;*/
/*}*/

/*.p-picklist .p-picklist-list .p-picklist-item {*/
/*  padding: 0.5rem 1.5rem;*/
/*  margin: 0;*/
/*  border: 0 none;*/
/*  color: #212529;*/
/*  background: transparent;*/
/*  transition: box-shadow 0.15s;*/
/*}*/

/*.p-picklist .p-picklist-list .p-picklist-item:focus {*/
/*  outline: 0 none;*/
/*  outline-offset: 0;*/
/*  box-shadow: inset 0 0 0 0.15rem rgba(38, 143, 255, 0.5);*/
/*}*/

/*.p-picklist .p-picklist-list .p-picklist-item.p-highlight {*/
/*  color: #ffffff;*/
/*  background: #007bff;*/
/*}*/

/*.p-picklist .p-picklist-list .p-picklist-empty-message {*/
/*  padding: 0.5rem 1.5rem;*/
/*  color: #212529;*/
/*}*/

/*.p-picklist .p-picklist-list:not(.cdk-drop-list-dragging) .p-picklist-item:not(.p-highlight):hover {*/
/*  background: #e9ecef;*/
/*  color: #212529;*/
/*}*/

/*.p-picklist-item.cdk-drag-preview {*/
/*  padding: 0.5rem 1.5rem;*/
/*  box-shadow: none;*/
/*  border: 0 none;*/
/*  color: #212529;*/
/*  background: #ffffff;*/
/*  margin: 0;*/
/*}*/

/*.p-timeline .p-timeline-event-marker {*/
/*  border: 0 none;*/
/*  border-radius: 50%;*/
/*  width: 1rem;*/
/*  height: 1rem;*/
/*  background-color: #007bff;*/
/*}*/

/*.p-timeline .p-timeline-event-connector {*/
/*  background-color: #dee2e6;*/
/*}*/

/*.p-timeline.p-timeline-vertical .p-timeline-event-opposite,*/
/*.p-timeline.p-timeline-vertical .p-timeline-event-content {*/
/*  padding: 0 1rem;*/
/*}*/

/*.p-timeline.p-timeline-vertical .p-timeline-event-connector {*/
/*  width: 2px;*/
/*}*/

/*.p-timeline.p-timeline-horizontal .p-timeline-event-opposite,*/
/*.p-timeline.p-timeline-horizontal .p-timeline-event-content {*/
/*  padding: 1rem 0;*/
/*}*/

/*.p-timeline.p-timeline-horizontal .p-timeline-event-connector {*/
/*  height: 2px;*/
/*}*/

/*.p-tree {*/
/*  border: 1px solid #dee2e6;*/
/*  background: #ffffff;*/
/*  color: #212529;*/
/*  padding: 1.25rem;*/
/*  border-radius: 4px;*/
/*}*/

/*.p-tree .p-tree-container .p-treenode {*/
/*  padding: 0.143rem;*/
/*}*/

/*.p-tree .p-tree-container .p-treenode .p-treenode-content {*/
/*  border-radius: 4px;*/
/*  transition: box-shadow 0.15s;*/
/*  padding: 0.286rem;*/
/*}*/

/*.p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler {*/
/*  margin-right: 0.5rem;*/
/*  width: 2rem;*/
/*  height: 2rem;*/
/*  color: #6c757d;*/
/*  border: 0 none;*/
/*  background: transparent;*/
/*  border-radius: 50%;*/
/*  transition: box-shadow 0.15s;*/
/*}*/

/*.p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler:enabled:hover {*/
/*  color: #495057;*/
/*  border-color: transparent;*/
/*  background: transparent;*/
/*}*/

/*.p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler:focus {*/
/*  outline: 0 none;*/
/*  outline-offset: 0;*/
/*  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);*/
/*}*/

/*.p-tree .p-tree-container .p-treenode .p-treenode-content .p-treenode-icon {*/
/*  margin-right: 0.5rem;*/
/*  color: #6c757d;*/
/*}*/

/*.p-tree .p-tree-container .p-treenode .p-treenode-content .p-checkbox {*/
/*  margin-right: 0.5rem;*/
/*}*/

/*.p-tree .p-tree-container .p-treenode .p-treenode-content .p-checkbox .p-indeterminate .p-checkbox-icon {*/
/*  color: #212529;*/
/*}*/

/*.p-tree .p-tree-container .p-treenode .p-treenode-content:focus {*/
/*  outline: 0 none;*/
/*  outline-offset: 0;*/
/*  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);*/
/*}*/

/*.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight {*/
/*  background: #007bff;*/
/*  color: #ffffff;*/
/*}*/

/*.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-tree-toggler,*/
/*.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-treenode-icon {*/
/*  color: #ffffff;*/
/*}*/

/*.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-tree-toggler:hover,*/
/*.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-treenode-icon:hover {*/
/*  color: #ffffff;*/
/*}*/

/*.p-tree .p-tree-container .p-treenode .p-treenode-content.p-treenode-selectable:not(.p-highlight):hover {*/
/*  background: #e9ecef;*/
/*  color: #212529;*/
/*}*/

/*.p-tree .p-tree-container .p-treenode .p-treenode-content.p-treenode-dragover {*/
/*  background: #e9ecef;*/
/*  color: #212529;*/
/*}*/

/*.p-tree .p-tree-filter-container {*/
/*  margin-bottom: 0.5rem;*/
/*}*/

/*.p-tree .p-tree-filter-container .p-tree-filter {*/
/*  width: 100%;*/
/*  padding-right: 1.75rem;*/
/*}*/

/*.p-tree .p-tree-filter-container .p-tree-filter-icon {*/
/*  right: 0.75rem;*/
/*  color: #495057;*/
/*}*/

/*.p-tree .p-treenode-children {*/
/*  padding: 0 0 0 1rem;*/
/*}*/

/*.p-tree .p-tree-loading-icon {*/
/*  font-size: 2rem;*/
/*}*/

/*.p-tree .p-treenode-droppoint.p-treenode-droppoint-active {*/
/*  background-color: #0062cc;*/
/*}*/

/*.p-tree.p-tree-horizontal .p-treenode .p-treenode-content {*/
/*  border-radius: 4px;*/
/*  border: 1px solid #dee2e6;*/
/*  background-color: #ffffff;*/
/*  color: #212529;*/
/*  padding: 0.286rem;*/
/*  transition: box-shadow 0.15s;*/
/*}*/

/*.p-tree.p-tree-horizontal .p-treenode .p-treenode-content.p-highlight {*/
/*  background-color: #007bff;*/
/*  color: #ffffff;*/
/*}*/

/*.p-tree.p-tree-horizontal .p-treenode .p-treenode-content.p-highlight .p-treenode-icon {*/
/*  color: #ffffff;*/
/*}*/

/*.p-tree.p-tree-horizontal .p-treenode .p-treenode-content .p-tree-toggler {*/
/*  margin-right: 0.5rem;*/
/*}*/

/*.p-tree.p-tree-horizontal .p-treenode .p-treenode-content .p-treenode-icon {*/
/*  color: #6c757d;*/
/*  margin-right: 0.5rem;*/
/*}*/

/*.p-tree.p-tree-horizontal .p-treenode .p-treenode-content .p-checkbox {*/
/*  margin-right: 0.5rem;*/
/*}*/

/*.p-tree.p-tree-horizontal .p-treenode .p-treenode-content .p-treenode-label:not(.p-highlight):hover {*/
/*  background-color: inherit;*/
/*  color: inherit;*/
/*}*/

/*.p-tree.p-tree-horizontal .p-treenode .p-treenode-content.p-treenode-selectable:not(.p-highlight):hover {*/
/*  background: #e9ecef;*/
/*  color: #212529;*/
/*}*/

/*.p-tree.p-tree-horizontal .p-treenode .p-treenode-content:focus {*/
/*  outline: 0 none;*/
/*  outline-offset: 0;*/
/*  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);*/
/*}*/

/*.p-treetable .p-paginator-top {*/
/*  border-width: 1px 0 0 0;*/
/*  border-radius: 0;*/
/*}*/

/*.p-treetable .p-paginator-bottom {*/
/*  border-width: 1px 0 0 0;*/
/*  border-radius: 0;*/
/*}*/

/*.p-treetable .p-treetable-header {*/
/*  background: #efefef;*/
/*  color: #212529;*/
/*  border: solid #dee2e6;*/
/*  border-width: 1px 0 0 0;*/
/*  padding: 1rem 1rem;*/
/*  font-weight: 600;*/
/*}*/

/*.p-treetable .p-treetable-footer {*/
/*  background: #efefef;*/
/*  color: #212529;*/
/*  border: 1px solid #dee2e6;*/
/*  border-width: 1px 0 1px 0;*/
/*  padding: 1rem 1rem;*/
/*  font-weight: 600;*/
/*}*/

/*.p-treetable .p-treetable-thead > tr > th {*/
/*  text-align: left;*/
/*  padding: 1rem 1rem;*/
/*  border: 1px solid #dee2e6;*/
/*  border-width: 1px 0 2px 0;*/
/*  font-weight: 600;*/
/*  color: #212529;*/
/*  background: #ffffff;*/
/*  transition: box-shadow 0.15s;*/
/*}*/

/*.p-treetable .p-treetable-tfoot > tr > td {*/
/*  text-align: left;*/
/*  padding: 1rem 1rem;*/
/*  border: 1px solid #dee2e6;*/
/*  border-width: 1px 0 1px 0;*/
/*  font-weight: 600;*/
/*  color: #212529;*/
/*  background: #ffffff;*/
/*}*/

/*.p-treetable .p-sortable-column {*/
/*  outline-color: rgba(38, 143, 255, 0.5);*/
/*}*/

/*.p-treetable .p-sortable-column .p-sortable-column-icon {*/
/*  color: #6c757d;*/
/*  margin-left: 0.5rem;*/
/*}*/

/*.p-treetable .p-sortable-column .p-sortable-column-badge {*/
/*  border-radius: 50%;*/
/*  height: 1.143rem;*/
/*  min-width: 1.143rem;*/
/*  line-height: 1.143rem;*/
/*  color: #ffffff;*/
/*  background: #007bff;*/
/*  margin-left: 0.5rem;*/
/*}*/

/*.p-treetable .p-sortable-column:not(.p-highlight):hover {*/
/*  background: #e9ecef;*/
/*  color: #212529;*/
/*}*/

/*.p-treetable .p-sortable-column:not(.p-highlight):hover .p-sortable-column-icon {*/
/*  color: #6c757d;*/
/*}*/

/*.p-treetable .p-sortable-column.p-highlight {*/
/*  background: #ffffff;*/
/*  color: #007bff;*/
/*}*/

/*.p-treetable .p-sortable-column.p-highlight .p-sortable-column-icon {*/
/*  color: #007bff;*/
/*}*/

/*.p-treetable .p-treetable-tbody > tr {*/
/*  background: #ffffff;*/
/*  color: #212529;*/
/*  transition: box-shadow 0.15s;*/
/*  outline-color: rgba(38, 143, 255, 0.5);*/
/*}*/

/*.p-treetable .p-treetable-tbody > tr > td {*/
/*  text-align: left;*/
/*  border: 1px solid #dee2e6;*/
/*  border-width: 1px 0 0 0;*/
/*  padding: 1rem 1rem;*/
/*}*/

/*.p-treetable .p-treetable-tbody > tr > td .p-treetable-toggler {*/
/*  width: 2rem;*/
/*  height: 2rem;*/
/*  color: #6c757d;*/
/*  border: 0 none;*/
/*  background: transparent;*/
/*  border-radius: 50%;*/
/*  transition: box-shadow 0.15s;*/
/*  margin-right: 0.5rem;*/
/*}*/

/*.p-treetable .p-treetable-tbody > tr > td .p-treetable-toggler:enabled:hover {*/
/*  color: #495057;*/
/*  border-color: transparent;*/
/*  background: transparent;*/
/*}*/

/*.p-treetable .p-treetable-tbody > tr > td .p-treetable-toggler:focus {*/
/*  outline: 0 none;*/
/*  outline-offset: 0;*/
/*  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);*/
/*}*/

/*.p-treetable .p-treetable-tbody > tr > td p-treetablecheckbox .p-checkbox {*/
/*  margin-right: 0.5rem;*/
/*}*/

/*.p-treetable .p-treetable-tbody > tr > td p-treetablecheckbox .p-checkbox .p-indeterminate .p-checkbox-icon {*/
/*  color: #212529;*/
/*}*/

/*.p-treetable .p-treetable-tbody > tr.p-highlight {*/
/*  background: #007bff;*/
/*  color: #ffffff;*/
/*}*/

/*.p-treetable .p-treetable-tbody > tr.p-highlight .p-treetable-toggler {*/
/*  color: #ffffff;*/
/*}*/

/*.p-treetable .p-treetable-tbody > tr.p-highlight .p-treetable-toggler:hover {*/
/*  color: #ffffff;*/
/*}*/

/*.p-treetable.p-treetable-hoverable-rows .p-treetable-tbody > tr:not(.p-highlight):hover {*/
/*  background: #e9ecef;*/
/*  color: #212529;*/
/*}*/

/*.p-treetable.p-treetable-hoverable-rows .p-treetable-tbody > tr:not(.p-highlight):hover .p-treetable-toggler {*/
/*  color: #212529;*/
/*}*/

/*.p-treetable .p-column-resizer-helper {*/
/*  background: #007bff;*/
/*}*/

/*.p-treetable .p-treetable-scrollable-header,*/
/*.p-treetable .p-treetable-scrollable-footer {*/
/*  background: #efefef;*/
/*}*/

/*.p-treetable .p-treetable-loading-icon {*/
/*  font-size: 2rem;*/
/*}*/

/*.p-treetable.p-treetable-gridlines .p-datatable-header {*/
/*  border-width: 1px 1px 0 1px;*/
/*}*/

/*.p-treetable.p-treetable-gridlines .p-treetable-footer {*/
/*  border-width: 0 1px 1px 1px;*/
/*}*/

/*.p-treetable.p-treetable-gridlines .p-treetable-top {*/
/*  border-width: 0 1px 0 1px;*/
/*}*/

/*.p-treetable.p-treetable-gridlines .p-treetable-bottom {*/
/*  border-width: 0 1px 1px 1px;*/
/*}*/

/*.p-treetable.p-treetable-gridlines .p-treetable-thead > tr > th {*/
/*  border-width: 1px;*/
/*}*/

/*.p-treetable.p-treetable-gridlines .p-treetable-tbody > tr > td {*/
/*  border-width: 1px;*/
/*}*/

/*.p-treetable.p-treetable-gridlines .p-treetable-tfoot > tr > td {*/
/*  border-width: 1px;*/
/*}*/

/*.p-treetable.p-treetable-sm .p-treetable-header {*/
/*  padding: 0.875rem 0.875rem;*/
/*}*/

/*.p-treetable.p-treetable-sm .p-treetable-thead > tr > th {*/
/*  padding: 0.5rem 0.5rem;*/
/*}*/

/*.p-treetable.p-treetable-sm .p-treetable-tbody > tr > td {*/
/*  padding: 0.5rem 0.5rem;*/
/*}*/

/*.p-treetable.p-treetable-sm .p-treetable-tfoot > tr > td {*/
/*  padding: 0.5rem 0.5rem;*/
/*}*/

/*.p-treetable.p-treetable-sm .p-treetable-footer {*/
/*  padding: 0.5rem 0.5rem;*/
/*}*/

/*.p-treetable.p-treetable-lg .p-treetable-header {*/
/*  padding: 1.25rem 1.25rem;*/
/*}*/

/*.p-treetable.p-treetable-lg .p-treetable-thead > tr > th {*/
/*  padding: 1.25rem 1.25rem;*/
/*}*/

/*.p-treetable.p-treetable-lg .p-treetable-tbody > tr > td {*/
/*  padding: 1.25rem 1.25rem;*/
/*}*/

/*.p-treetable.p-treetable-lg .p-treetable-tfoot > tr > td {*/
/*  padding: 1.25rem 1.25rem;*/
/*}*/

/*.p-treetable.p-treetable-lg .p-treetable-footer {*/
/*  padding: 1.25rem 1.25rem;*/
/*}*/

/*.p-virtualscroller .p-virtualscroller-header {*/
/*  background: #efefef;*/
/*  color: #212529;*/
/*  border: solid #dee2e6;*/
/*  border-width: 1px 0 0 0;*/
/*  padding: 1rem 1rem;*/
/*  font-weight: 600;*/
/*}*/

/*.p-virtualscroller .p-virtualscroller-content {*/
/*  background: #ffffff;*/
/*  color: #212529;*/
/*  border: 0 none;*/
/*  padding: 0;*/
/*}*/

/*.p-virtualscroller .p-virtualscroller-footer {*/
/*  background: #efefef;*/
/*  color: #212529;*/
/*  border: 1px solid #dee2e6;*/
/*  border-width: 1px 0 1px 0;*/
/*  padding: 1rem 1rem;*/
/*  font-weight: 600;*/
/*  border-bottom-left-radius: 4px;*/
/*  border-bottom-right-radius: 4px;*/
/*}*/

/*.p-card {*/
/*  background: #ffffff;*/
/*  color: #212529;*/
/*  box-shadow:*/
/*    0 2px 1px -1px rgba(0, 0, 0, 0.2),*/
/*    0 1px 1px 0 rgba(0, 0, 0, 0.14),*/
/*    0 1px 3px 0 rgba(0, 0, 0, 0.12);*/
/*  border-radius: 4px;*/
/*}*/

/*.p-card .p-card-body {*/
/*  padding: 1.5rem;*/
/*}*/

/*.p-card .p-card-title {*/
/*  font-size: 1.5rem;*/
/*  font-weight: 700;*/
/*  margin-bottom: 0.5rem;*/
/*}*/

/*.p-card .p-card-subtitle {*/
/*  font-weight: 400;*/
/*  margin-bottom: 0.5rem;*/
/*  color: #6c757d;*/
/*}*/

/*.p-card .p-card-content {*/
/*  padding: 1rem 0;*/
/*}*/

/*.p-card .p-card-footer {*/
/*  padding: 1rem 0 0 0;*/
/*}*/

/*.p-fieldset {*/
/*  border: 1px solid #dee2e6;*/
/*  background: #ffffff;*/
/*  color: #212529;*/
/*  border-radius: 4px;*/
/*}*/

/*.p-fieldset .p-fieldset-legend {*/
/*  padding: 1rem 1.25rem;*/
/*  border: 1px solid #dee2e6;*/
/*  color: #212529;*/
/*  background: #efefef;*/
/*  font-weight: 600;*/
/*  border-radius: 4px;*/
/*}*/

/*.p-fieldset.p-fieldset-toggleable .p-fieldset-legend {*/
/*  padding: 0;*/
/*  transition: box-shadow 0.15s;*/
/*}*/

/*.p-fieldset.p-fieldset-toggleable .p-fieldset-legend a {*/
/*  padding: 1rem 1.25rem;*/
/*  color: #212529;*/
/*  border-radius: 4px;*/
/*  transition: box-shadow 0.15s;*/
/*}*/

/*.p-fieldset.p-fieldset-toggleable .p-fieldset-legend a .p-fieldset-toggler {*/
/*  margin-right: 0.5rem;*/
/*}*/

/*.p-fieldset.p-fieldset-toggleable .p-fieldset-legend a:focus {*/
/*  outline: 0 none;*/
/*  outline-offset: 0;*/
/*  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);*/
/*}*/

/*.p-fieldset.p-fieldset-toggleable .p-fieldset-legend:hover {*/
/*  background: #e9ecef;*/
/*  border-color: #dee2e6;*/
/*  color: #212529;*/
/*}*/

/*.p-fieldset .p-fieldset-content {*/
/*  padding: 1.25rem;*/
/*}*/

/*.p-panel .p-panel-header {*/
/*  border: 1px solid #dee2e6;*/
/*  padding: 1rem 1.25rem;*/
/*  background: #efefef;*/
/*  color: #212529;*/
/*  border-top-right-radius: 4px;*/
/*  border-top-left-radius: 4px;*/
/*}*/

/*.p-panel .p-panel-header .p-panel-title {*/
/*  font-weight: 600;*/
/*}*/

/*.p-panel .p-panel-header .p-panel-header-icon {*/
/*  width: 2rem;*/
/*  height: 2rem;*/
/*  color: #6c757d;*/
/*  border: 0 none;*/
/*  background: transparent;*/
/*  border-radius: 50%;*/
/*  transition: box-shadow 0.15s;*/
/*}*/

/*.p-panel .p-panel-header .p-panel-header-icon:enabled:hover {*/
/*  color: #495057;*/
/*  border-color: transparent;*/
/*  background: transparent;*/
/*}*/

/*.p-panel .p-panel-header .p-panel-header-icon:focus {*/
/*  outline: 0 none;*/
/*  outline-offset: 0;*/
/*  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);*/
/*}*/

/*.p-panel.p-panel-toggleable .p-panel-header {*/
/*  padding: 0.5rem 1.25rem;*/
/*}*/

/*.p-panel .p-panel-content {*/
/*  padding: 1.25rem;*/
/*  border: 1px solid #dee2e6;*/
/*  background: #ffffff;*/
/*  color: #212529;*/
/*  border-bottom-right-radius: 4px;*/
/*  border-bottom-left-radius: 4px;*/
/*  border-top: 0 none;*/
/*}*/

/*.p-panel .p-panel-footer {*/
/*  padding: 0.5rem 1.25rem;*/
/*  border: 1px solid #dee2e6;*/
/*  background: #ffffff;*/
/*  color: #212529;*/
/*  border-top: 0 none;*/
/*}*/

/*.p-scrollpanel .p-scrollpanel-bar {*/
/*  background: #efefef;*/
/*  border: 0 none;*/
/*}*/

/*.p-splitter {*/
/*  border: 1px solid #dee2e6;*/
/*  background: #ffffff;*/
/*  border-radius: 4px;*/
/*  color: #212529;*/
/*}*/

/*.p-splitter .p-splitter-gutter {*/
/*  transition: box-shadow 0.15s;*/
/*  background: #efefef;*/
/*}*/

/*.p-splitter .p-splitter-gutter .p-splitter-gutter-handle {*/
/*  background: #dee2e6;*/
/*}*/

/*.p-splitter .p-splitter-gutter-resizing {*/
/*  background: #dee2e6;*/
/*}*/

/*.p-tabview .p-tabview-nav {*/
/*  background: transparent;*/
/*  border: 1px solid #dee2e6;*/
/*  border-width: 0 0 1px 0;*/
/*}*/

/*.p-tabview .p-tabview-nav li {*/
/*  margin-right: 0;*/
/*}*/

/*.p-tabview .p-tabview-nav li .p-tabview-nav-link {*/
/*  border: solid;*/
/*  border-width: 1px;*/
/*  border-color: #ffffff #ffffff #dee2e6 #ffffff;*/
/*  background: #ffffff;*/
/*  color: #6c757d;*/
/*  padding: 0.75rem 1rem;*/
/*  font-weight: 600;*/
/*  border-top-right-radius: 4px;*/
/*  border-top-left-radius: 4px;*/
/*  transition: box-shadow 0.15s;*/
/*  margin: 0 0 -1px 0;*/
/*}*/

/*.p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {*/
/*  outline: 0 none;*/
/*  outline-offset: 0;*/
/*  box-shadow: inset 0 0 0 0.2rem rgba(38, 143, 255, 0.5);*/
/*}*/

/*.p-tabview .p-tabview-nav li:not(.p-highlight):not(.p-disabled):hover .p-tabview-nav-link {*/
/*  background: #ffffff;*/
/*  border-color: #dee2e6;*/
/*  color: #6c757d;*/
/*}*/

/*.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {*/
/*  background: #ffffff;*/
/*  border-color: #dee2e6 #dee2e6 #ffffff #dee2e6;*/
/*  color: #495057;*/
/*}*/

/*.p-tabview .p-tabview-left-icon {*/
/*  margin-right: 0.5rem;*/
/*}*/

/*.p-tabview .p-tabview-right-icon {*/
/*  margin-left: 0.5rem;*/
/*}*/

/*.p-tabview .p-tabview-close {*/
/*  margin-left: 0.5rem;*/
/*}*/

/*.p-tabview .p-tabview-nav-btn.p-link {*/
/*  background: #ffffff;*/
/*  color: #495057;*/
/*  width: 2.357rem;*/
/*  box-shadow:*/
/*    0 3px 1px -2px rgba(0, 0, 0, 0.2),*/
/*    0 2px 2px 0 rgba(0, 0, 0, 0.14),*/
/*    0 1px 5px 0 rgba(0, 0, 0, 0.12);*/
/*  border-radius: 0;*/
/*}*/

/*.p-tabview .p-tabview-nav-btn.p-link:focus {*/
/*  outline: 0 none;*/
/*  outline-offset: 0;*/
/*  box-shadow: inset 0 0 0 0.2rem rgba(38, 143, 255, 0.5);*/
/*}*/

/*.p-tabview .p-tabview-panels {*/
/*  background: #ffffff;*/
/*  padding: 1.25rem;*/
/*  border: 0 none;*/
/*  color: #212529;*/
/*  border-bottom-right-radius: 4px;*/
/*  border-bottom-left-radius: 4px;*/
/*}*/

/*.p-toolbar {*/
/*  background: #efefef;*/
/*  border: 1px solid #dee2e6;*/
/*  padding: 1rem 1.25rem;*/
/*  border-radius: 4px;*/
/*}*/

/*.p-toolbar .p-toolbar-separator {*/
/*  margin: 0 0.5rem;*/
/*}*/

/*.p-confirm-popup {*/
/*  background: #ffffff;*/
/*  color: #212529;*/
/*  border: 1px solid rgba(0, 0, 0, 0.2);*/
/*  border-radius: 4px;*/
/*  box-shadow: none;*/
/*}*/

/*.p-confirm-popup .p-confirm-popup-content {*/
/*  padding: 1.25rem;*/
/*}*/

/*.p-confirm-popup .p-confirm-popup-footer {*/
/*  text-align: right;*/
/*  padding: 0.5rem 1.25rem;*/
/*}*/

/*.p-confirm-popup .p-confirm-popup-footer button {*/
/*  margin: 0 0.5rem 0 0;*/
/*  width: auto;*/
/*}*/

/*.p-confirm-popup .p-confirm-popup-footer button:last-child {*/
/*  margin: 0;*/
/*}*/

/*.p-confirm-popup:after {*/
/*  border: solid transparent;*/
/*  border-color: rgba(255, 255, 255, 0);*/
/*  border-bottom-color: #ffffff;*/
/*}*/

/*.p-confirm-popup:before {*/
/*  border: solid transparent;*/
/*  border-color: rgba(0, 0, 0, 0);*/
/*  border-bottom-color: rgba(0, 0, 0, 0.2);*/
/*}*/

/*.p-confirm-popup.p-confirm-popup-flipped:after {*/
/*  border-top-color: #ffffff;*/
/*}*/

/*.p-confirm-popup.p-confirm-popup-flipped:before {*/
/*  border-top-color: rgba(0, 0, 0, 0.2);*/
/*}*/

/*.p-confirm-popup .p-confirm-popup-icon {*/
/*  font-size: 1.5rem;*/
/*}*/

/*.p-confirm-popup .p-confirm-popup-message {*/
/*  margin-left: 1rem;*/
/*}*/

/*.p-sidebar {*/
/*  background: #ffffff;*/
/*  color: #212529;*/
/*  border: 1px solid rgba(0, 0, 0, 0.2);*/
/*  box-shadow: none;*/
/*}*/

/*.p-sidebar .p-sidebar-header {*/
/*  padding: 1rem 1.25rem;*/
/*}*/

/*.p-sidebar .p-sidebar-header .p-sidebar-close,*/
/*.p-sidebar .p-sidebar-header .p-sidebar-icon {*/
/*  width: 2rem;*/
/*  height: 2rem;*/
/*  color: #6c757d;*/
/*  border: 0 none;*/
/*  background: transparent;*/
/*  border-radius: 50%;*/
/*  transition: box-shadow 0.15s;*/
/*}*/

/*.p-sidebar .p-sidebar-header .p-sidebar-close:enabled:hover,*/
/*.p-sidebar .p-sidebar-header .p-sidebar-icon:enabled:hover {*/
/*  color: #495057;*/
/*  border-color: transparent;*/
/*  background: transparent;*/
/*}*/

/*.p-sidebar .p-sidebar-header .p-sidebar-close:focus,*/
/*.p-sidebar .p-sidebar-header .p-sidebar-icon:focus {*/
/*  outline: 0 none;*/
/*  outline-offset: 0;*/
/*  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);*/
/*}*/

/*.p-sidebar .p-sidebar-header + .p-sidebar-content {*/
/*  padding-top: 0;*/
/*}*/

/*.p-sidebar .p-sidebar-content {*/
/*  padding: 1.25rem;*/
/*}*/

/*.p-contextmenu {*/
/*  padding: 0.5rem 0;*/
/*  background: #ffffff;*/
/*  color: #212529;*/
/*  border: 1px solid rgba(0, 0, 0, 0.15);*/
/*  box-shadow: none;*/
/*  border-radius: 4px;*/
/*  width: 12.5rem;*/
/*}*/

/*.p-contextmenu .p-menuitem-link {*/
/*  padding: 0.75rem 1rem;*/
/*  color: #212529;*/
/*  border-radius: 0;*/
/*  transition: box-shadow 0.15s;*/
/*  user-select: none;*/
/*}*/

/*.p-contextmenu .p-menuitem-link .p-menuitem-text {*/
/*  color: #212529;*/
/*}*/

/*.p-contextmenu .p-menuitem-link .p-menuitem-icon {*/
/*  color: #212529;*/
/*  margin-right: 0.5rem;*/
/*}*/

/*.p-contextmenu .p-menuitem-link .p-submenu-icon {*/
/*  color: #212529;*/
/*}*/

/*.p-contextmenu .p-menuitem-link:not(.p-disabled):hover {*/
/*  background: #e9ecef;*/
/*}*/

/*.p-contextmenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {*/
/*  color: #212529;*/
/*}*/

/*.p-contextmenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {*/
/*  color: #212529;*/
/*}*/

/*.p-contextmenu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {*/
/*  color: #212529;*/
/*}*/

/*.p-contextmenu .p-menuitem-link:focus {*/
/*  outline: 0 none;*/
/*  outline-offset: 0;*/
/*  box-shadow: inset 0 0 0 0.15rem rgba(38, 143, 255, 0.5);*/
/*}*/

/*.p-contextmenu .p-submenu-list {*/
/*  padding: 0.5rem 0;*/
/*  background: #ffffff;*/
/*  border: 1px solid rgba(0, 0, 0, 0.15);*/
/*  box-shadow: none;*/
/*  border-radius: 4px;*/
/*}*/

/*.p-contextmenu .p-menuitem {*/
/*  margin: 0;*/
/*}*/

/*.p-contextmenu .p-menuitem:last-child {*/
/*  margin: 0;*/
/*}*/

/*.p-contextmenu .p-menuitem.p-menuitem-active > .p-menuitem-link {*/
/*  background: #e9ecef;*/
/*}*/

/*.p-contextmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text {*/
/*  color: #212529;*/
/*}*/

/*.p-contextmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon,*/
/*.p-contextmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon {*/
/*  color: #212529;*/
/*}*/

/*.p-contextmenu .p-menu-separator {*/
/*  border-top: 1px solid #dee2e6;*/
/*  margin: 0.5rem 0;*/
/*}*/

/*.p-contextmenu .p-submenu-icon {*/
/*  font-size: 0.875rem;*/
/*}*/

/*.p-dock .p-dock-list {*/
/*  background: rgba(255, 255, 255, 0.1);*/
/*  border: 1px solid rgba(255, 255, 255, 0.2);*/
/*  padding: 0.5rem 0.5rem;*/
/*  border-radius: 0.5rem;*/
/*}*/

/*.p-dock .p-dock-item {*/
/*  padding: 0.5rem;*/
/*}*/

/*.p-dock .p-dock-action {*/
/*  width: 4rem;*/
/*  height: 4rem;*/
/*}*/

/*.p-dock.p-dock-top .p-dock-item-second-prev,*/
/*.p-dock.p-dock-top .p-dock-item-second-next,*/
/*.p-dock.p-dock-bottom .p-dock-item-second-prev,*/
/*.p-dock.p-dock-bottom .p-dock-item-second-next {*/
/*  margin: 0 0.9rem;*/
/*}*/

/*.p-dock.p-dock-top .p-dock-item-prev,*/
/*.p-dock.p-dock-top .p-dock-item-next,*/
/*.p-dock.p-dock-bottom .p-dock-item-prev,*/
/*.p-dock.p-dock-bottom .p-dock-item-next {*/
/*  margin: 0 1.3rem;*/
/*}*/

/*.p-dock.p-dock-top .p-dock-item-current,*/
/*.p-dock.p-dock-bottom .p-dock-item-current {*/
/*  margin: 0 1.5rem;*/
/*}*/

/*.p-dock.p-dock-left .p-dock-item-second-prev,*/
/*.p-dock.p-dock-left .p-dock-item-second-next,*/
/*.p-dock.p-dock-right .p-dock-item-second-prev,*/
/*.p-dock.p-dock-right .p-dock-item-second-next {*/
/*  margin: 0.9rem 0;*/
/*}*/

/*.p-dock.p-dock-left .p-dock-item-prev,*/
/*.p-dock.p-dock-left .p-dock-item-next,*/
/*.p-dock.p-dock-right .p-dock-item-prev,*/
/*.p-dock.p-dock-right .p-dock-item-next {*/
/*  margin: 1.3rem 0;*/
/*}*/

/*.p-dock.p-dock-left .p-dock-item-current,*/
/*.p-dock.p-dock-right .p-dock-item-current {*/
/*  margin: 1.5rem 0;*/
/*}*/

/*@media screen and (max-width: 960px) {*/
/*  .p-dock.p-dock-top .p-dock-list-container,*/
/*  .p-dock.p-dock-bottom .p-dock-list-container {*/
/*    overflow-x: auto;*/
/*    width: 100%;*/
/*  }*/

/*  .p-dock.p-dock-top .p-dock-list-container .p-dock-list,*/
/*  .p-dock.p-dock-bottom .p-dock-list-container .p-dock-list {*/
/*    margin: 0 auto;*/
/*  }*/

/*  .p-dock.p-dock-left .p-dock-list-container,*/
/*  .p-dock.p-dock-right .p-dock-list-container {*/
/*    overflow-y: auto;*/
/*    height: 100%;*/
/*  }*/

/*  .p-dock.p-dock-left .p-dock-list-container .p-dock-list,*/
/*  .p-dock.p-dock-right .p-dock-list-container .p-dock-list {*/
/*    margin: auto 0;*/
/*  }*/

/*  .p-dock .p-dock-list .p-dock-item {*/
/*    transform: none;*/
/*    margin: 0;*/
/*  }*/
/*}*/

/*.p-megamenu {*/
/*  padding: 0.5rem 1rem;*/
/*  background: #efefef;*/
/*  color: rgba(0, 0, 0, 0.9);*/
/*  border: 0 none;*/
/*  border-radius: 4px;*/
/*}*/

/*.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link {*/
/*  padding: 1rem;*/
/*  color: rgba(0, 0, 0, 0.5);*/
/*  border-radius: 4px;*/
/*  transition: box-shadow 0.15s;*/
/*  user-select: none;*/
/*}*/

/*.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-text {*/
/*  color: rgba(0, 0, 0, 0.5);*/
/*}*/

/*.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-icon {*/
/*  color: rgba(0, 0, 0, 0.5);*/
/*  margin-right: 0.5rem;*/
/*}*/

/*.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link .p-submenu-icon {*/
/*  color: rgba(0, 0, 0, 0.5);*/
/*  margin-left: 0.5rem;*/
/*}*/

/*.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover {*/
/*  background: transparent;*/
/*}*/

/*.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {*/
/*  color: rgba(0, 0, 0, 0.7);*/
/*}*/

/*.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {*/
/*  color: rgba(0, 0, 0, 0.7);*/
/*}*/

/*.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {*/
/*  color: rgba(0, 0, 0, 0.7);*/
/*}*/

/*.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link:focus {*/
/*  outline: 0 none;*/
/*  outline-offset: 0;*/
/*  box-shadow: inset 0 0 0 0.15rem rgba(38, 143, 255, 0.5);*/
/*}*/

/*.p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link,*/
/*.p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover {*/
/*  background: transparent;*/
/*}*/

/*.p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text,*/
/*.p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {*/
/*  color: rgba(0, 0, 0, 0.9);*/
/*}*/

/*.p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon,*/
/*.p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {*/
/*  color: rgba(0, 0, 0, 0.9);*/
/*}*/

/*.p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon,*/
/*.p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {*/
/*  color: rgba(0, 0, 0, 0.9);*/
/*}*/

/*.p-megamenu .p-menuitem-link {*/
/*  padding: 0.75rem 1rem;*/
/*  color: #212529;*/
/*  border-radius: 0;*/
/*  transition: box-shadow 0.15s;*/
/*  user-select: none;*/
/*}*/

/*.p-megamenu .p-menuitem-link .p-menuitem-text {*/
/*  color: #212529;*/
/*}*/

/*.p-megamenu .p-menuitem-link .p-menuitem-icon {*/
/*  color: #212529;*/
/*  margin-right: 0.5rem;*/
/*}*/

/*.p-megamenu .p-menuitem-link .p-submenu-icon {*/
/*  color: #212529;*/
/*}*/

/*.p-megamenu .p-menuitem-link:not(.p-disabled):hover {*/
/*  background: #e9ecef;*/
/*}*/

/*.p-megamenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {*/
/*  color: #212529;*/
/*}*/

/*.p-megamenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {*/
/*  color: #212529;*/
/*}*/

/*.p-megamenu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {*/
/*  color: #212529;*/
/*}*/

/*.p-megamenu .p-menuitem-link:focus {*/
/*  outline: 0 none;*/
/*  outline-offset: 0;*/
/*  box-shadow: inset 0 0 0 0.15rem rgba(38, 143, 255, 0.5);*/
/*}*/

/*.p-megamenu .p-megamenu-panel {*/
/*  background: #ffffff;*/
/*  color: #212529;*/
/*  border: 1px solid rgba(0, 0, 0, 0.15);*/
/*  box-shadow: none;*/
/*}*/

/*.p-megamenu .p-megamenu-submenu-header {*/
/*  margin: 0;*/
/*  padding: 0.75rem 1rem;*/
/*  color: #212529;*/
/*  background: #ffffff;*/
/*  font-weight: 600;*/
/*  border-top-right-radius: 4px;*/
/*  border-top-left-radius: 4px;*/
/*}*/

/*.p-megamenu .p-megamenu-submenu {*/
/*  padding: 0.5rem 0;*/
/*  width: 12.5rem;*/
/*}*/

/*.p-megamenu .p-megamenu-submenu .p-menu-separator {*/
/*  border-top: 1px solid #dee2e6;*/
/*  margin: 0.5rem 0;*/
/*}*/

/*.p-megamenu .p-megamenu-submenu .p-menuitem {*/
/*  margin: 0;*/
/*}*/

/*.p-megamenu .p-megamenu-submenu .p-menuitem:last-child {*/
/*  margin: 0;*/
/*}*/

/*.p-megamenu .p-menuitem.p-menuitem-active > .p-menuitem-link {*/
/*  background: #e9ecef;*/
/*}*/

/*.p-megamenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text {*/
/*  color: #212529;*/
/*}*/

/*.p-megamenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon,*/
/*.p-megamenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon {*/
/*  color: #212529;*/
/*}*/

/*.p-megamenu.p-megamenu-vertical {*/
/*  width: 12.5rem;*/
/*  padding: 0.5rem 0;*/
/*}*/

/*.p-megamenu.p-megamenu-vertical .p-menuitem {*/
/*  margin: 0;*/
/*}*/

/*.p-megamenu.p-megamenu-vertical .p-menuitem:last-child {*/
/*  margin: 0;*/
/*}*/

/*.p-menubar {*/
/*  padding: 0.5rem 1rem;*/
/*  background: #efefef;*/
/*  color: rgba(0, 0, 0, 0.9);*/
/*  border: 0 none;*/
/*  border-radius: 4px;*/
/*}*/

/*.p-menubar .p-menuitem-link {*/
/*  padding: 0.75rem 1rem;*/
/*  color: #212529;*/
/*  border-radius: 0;*/
/*  transition: box-shadow 0.15s;*/
/*  user-select: none;*/
/*}*/

/*.p-menubar .p-menuitem-link .p-menuitem-text {*/
/*  color: #212529;*/
/*}*/

/*.p-menubar .p-menuitem-link .p-menuitem-icon {*/
/*  color: #212529;*/
/*  margin-right: 0.5rem;*/
/*}*/

/*.p-menubar .p-menuitem-link .p-submenu-icon {*/
/*  color: #212529;*/
/*}*/

/*.p-menubar .p-menuitem-link:not(.p-disabled):hover {*/
/*  background: #e9ecef;*/
/*}*/

/*.p-menubar .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {*/
/*  color: #212529;*/
/*}*/

/*.p-menubar .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {*/
/*  color: #212529;*/
/*}*/

/*.p-menubar .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {*/
/*  color: #212529;*/
/*}*/

/*.p-menubar .p-menuitem-link:focus {*/
/*  outline: 0 none;*/
/*  outline-offset: 0;*/
/*  box-shadow: inset 0 0 0 0.15rem rgba(38, 143, 255, 0.5);*/
/*}*/

/*.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link {*/
/*  padding: 1rem;*/
/*  color: rgba(0, 0, 0, 0.5);*/
/*  border-radius: 4px;*/
/*  transition: box-shadow 0.15s;*/
/*  user-select: none;*/
/*}*/

/*.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-text {*/
/*  color: rgba(0, 0, 0, 0.5);*/
/*}*/

/*.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-icon {*/
/*  color: rgba(0, 0, 0, 0.5);*/
/*  margin-right: 0.5rem;*/
/*}*/

/*.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-submenu-icon {*/
/*  color: rgba(0, 0, 0, 0.5);*/
/*  margin-left: 0.5rem;*/
/*}*/

/*.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover {*/
/*  background: transparent;*/
/*}*/

/*.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {*/
/*  color: rgba(0, 0, 0, 0.7);*/
/*}*/

/*.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {*/
/*  color: rgba(0, 0, 0, 0.7);*/
/*}*/

/*.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {*/
/*  color: rgba(0, 0, 0, 0.7);*/
/*}*/

/*.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:focus {*/
/*  outline: 0 none;*/
/*  outline-offset: 0;*/
/*  box-shadow: inset 0 0 0 0.15rem rgba(38, 143, 255, 0.5);*/
/*}*/

/*.p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link,*/
/*.p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover {*/
/*  background: transparent;*/
/*}*/

/*.p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text,*/
/*.p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {*/
/*  color: rgba(0, 0, 0, 0.9);*/
/*}*/

/*.p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon,*/
/*.p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {*/
/*  color: rgba(0, 0, 0, 0.9);*/
/*}*/

/*.p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon,*/
/*.p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {*/
/*  color: rgba(0, 0, 0, 0.9);*/
/*}*/

/*.p-menubar .p-submenu-list {*/
/*  padding: 0.5rem 0;*/
/*  background: #ffffff;*/
/*  border: 1px solid rgba(0, 0, 0, 0.15);*/
/*  box-shadow: none;*/
/*  width: 12.5rem;*/
/*}*/

/*.p-menubar .p-submenu-list .p-menu-separator {*/
/*  border-top: 1px solid #dee2e6;*/
/*  margin: 0.5rem 0;*/
/*}*/

/*.p-menubar .p-submenu-list .p-submenu-icon {*/
/*  font-size: 0.875rem;*/
/*}*/

/*.p-menubar .p-submenu-list .p-menuitem {*/
/*  margin: 0;*/
/*}*/

/*.p-menubar .p-submenu-list .p-menuitem:last-child {*/
/*  margin: 0;*/
/*}*/

/*.p-menubar .p-menuitem.p-menuitem-active > .p-menuitem-link {*/
/*  background: #e9ecef;*/
/*}*/

/*.p-menubar .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text {*/
/*  color: #212529;*/
/*}*/

/*.p-menubar .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon,*/
/*.p-menubar .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon {*/
/*  color: #212529;*/
/*}*/

/*@media screen and (max-width: 960px) {*/
/*  .p-menubar {*/
/*    position: relative;*/
/*  }*/

/*  .p-menubar .p-menubar-button {*/
/*    display: flex;*/
/*    width: 2rem;*/
/*    height: 2rem;*/
/*    color: rgba(0, 0, 0, 0.5);*/
/*    border-radius: 50%;*/
/*    transition: box-shadow 0.15s;*/
/*  }*/

/*  .p-menubar .p-menubar-button:hover {*/
/*    color: rgba(0, 0, 0, 0.7);*/
/*    background: transparent;*/
/*  }*/

/*  .p-menubar .p-menubar-button:focus {*/
/*    outline: 0 none;*/
/*    outline-offset: 0;*/
/*    box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);*/
/*  }*/

/*  .p-menubar .p-menubar-root-list {*/
/*    position: absolute;*/
/*    display: none;*/
/*    padding: 0.5rem 0;*/
/*    background: #ffffff;*/
/*    border: 1px solid rgba(0, 0, 0, 0.15);*/
/*    box-shadow: none;*/
/*    width: 100%;*/
/*  }*/

/*  .p-menubar .p-menubar-root-list .p-menu-separator {*/
/*    border-top: 1px solid #dee2e6;*/
/*    margin: 0.5rem 0;*/
/*  }*/

/*  .p-menubar .p-menubar-root-list .p-submenu-icon {*/
/*    font-size: 0.875rem;*/
/*  }*/

/*  .p-menubar .p-menubar-root-list > .p-menuitem {*/
/*    width: 100%;*/
/*    position: static;*/
/*  }*/

/*  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link {*/
/*    padding: 0.75rem 1rem;*/
/*    color: #212529;*/
/*    border-radius: 0;*/
/*    transition: box-shadow 0.15s;*/
/*    user-select: none;*/
/*  }*/

/*  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-text {*/
/*    color: #212529;*/
/*  }*/

/*  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-icon {*/
/*    color: #212529;*/
/*    margin-right: 0.5rem;*/
/*  }*/

/*  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-submenu-icon {*/
/*    color: #212529;*/
/*  }*/

/*  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover {*/
/*    background: #e9ecef;*/
/*  }*/

/*  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {*/
/*    color: #212529;*/
/*  }*/

/*  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {*/
/*    color: #212529;*/
/*  }*/

/*  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {*/
/*    color: #212529;*/
/*  }*/

/*  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:focus {*/
/*    outline: 0 none;*/
/*    outline-offset: 0;*/
/*    box-shadow: inset 0 0 0 0.15rem rgba(38, 143, 255, 0.5);*/
/*  }*/

/*  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link > .p-submenu-icon {*/
/*    margin-left: auto;*/
/*    transition: transform 0.15s;*/
/*  }*/

/*  .p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link > .p-submenu-icon {*/
/*    transform: rotate(-180deg);*/
/*  }*/

/*  .p-menubar .p-menubar-root-list .p-submenu-list {*/
/*    width: 100%;*/
/*    position: static;*/
/*    box-shadow: none;*/
/*    border: 0 none;*/
/*  }*/

/*  .p-menubar .p-menubar-root-list .p-submenu-list .p-submenu-icon {*/
/*    transition: transform 0.15s;*/
/*    transform: rotate(90deg);*/
/*  }*/

/*  .p-menubar .p-menubar-root-list .p-submenu-list .p-menuitem-active > .p-menuitem-link > .p-submenu-icon {*/
/*    transform: rotate(-90deg);*/
/*  }*/

/*  .p-menubar .p-menubar-root-list .p-menuitem {*/
/*    width: 100%;*/
/*    position: static;*/
/*  }*/

/*  .p-menubar .p-menubar-root-list ul li a {*/
/*    padding-left: 2.25rem;*/
/*  }*/

/*  .p-menubar .p-menubar-root-list ul li ul li a {*/
/*    padding-left: 3.75rem;*/
/*  }*/

/*  .p-menubar .p-menubar-root-list ul li ul li ul li a {*/
/*    padding-left: 5.25rem;*/
/*  }*/

/*  .p-menubar .p-menubar-root-list ul li ul li ul li ul li a {*/
/*    padding-left: 6.75rem;*/
/*  }*/

/*  .p-menubar .p-menubar-root-list ul li ul li ul li ul li ul li a {*/
/*    padding-left: 8.25rem;*/
/*  }*/

/*  .p-menubar.p-menubar-mobile-active .p-menubar-root-list {*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*    top: 100%;*/
/*    left: 0;*/
/*    z-index: 1;*/
/*  }*/
/*}*/

/*.p-slidemenu {*/
/*  padding: 0.5rem 0;*/
/*  background: #ffffff;*/
/*  color: #212529;*/
/*  border: 1px solid #dee2e6;*/
/*  border-radius: 4px;*/
/*  width: 12.5rem;*/
/*}*/

/*.p-slidemenu .p-menuitem-link {*/
/*  padding: 0.75rem 1rem;*/
/*  color: #212529;*/
/*  border-radius: 0;*/
/*  transition: box-shadow 0.15s;*/
/*  user-select: none;*/
/*}*/

/*.p-slidemenu .p-menuitem-link .p-menuitem-text {*/
/*  color: #212529;*/
/*}*/

/*.p-slidemenu .p-menuitem-link .p-menuitem-icon {*/
/*  color: #212529;*/
/*  margin-right: 0.5rem;*/
/*}*/

/*.p-slidemenu .p-menuitem-link .p-submenu-icon {*/
/*  color: #212529;*/
/*}*/

/*.p-slidemenu .p-menuitem-link:not(.p-disabled):hover {*/
/*  background: #e9ecef;*/
/*}*/

/*.p-slidemenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {*/
/*  color: #212529;*/
/*}*/

/*.p-slidemenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {*/
/*  color: #212529;*/
/*}*/

/*.p-slidemenu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {*/
/*  color: #212529;*/
/*}*/

/*.p-slidemenu .p-menuitem-link:focus {*/
/*  outline: 0 none;*/
/*  outline-offset: 0;*/
/*  box-shadow: inset 0 0 0 0.15rem rgba(38, 143, 255, 0.5);*/
/*}*/

/*.p-slidemenu.p-slidemenu-overlay {*/
/*  background: #ffffff;*/
/*  border: 1px solid rgba(0, 0, 0, 0.15);*/
/*  box-shadow: none;*/
/*}*/

/*.p-slidemenu .p-slidemenu-list {*/
/*  padding: 0.5rem 0;*/
/*  background: #ffffff;*/
/*  border: 1px solid rgba(0, 0, 0, 0.15);*/
/*  box-shadow: none;*/
/*}*/

/*.p-slidemenu .p-slidemenu.p-slidemenu-active > .p-slidemenu-link {*/
/*  background: #e9ecef;*/
/*}*/

/*.p-slidemenu .p-slidemenu.p-slidemenu-active > .p-slidemenu-link .p-slidemenu-text {*/
/*  color: #212529;*/
/*}*/

/*.p-slidemenu .p-slidemenu.p-slidemenu-active > .p-slidemenu-link .p-slidemenu-icon,*/
/*.p-slidemenu .p-slidemenu.p-slidemenu-active > .p-slidemenu-link .p-slidemenu-icon {*/
/*  color: #212529;*/
/*}*/

/*.p-slidemenu .p-slidemenu-separator {*/
/*  border-top: 1px solid #dee2e6;*/
/*  margin: 0.5rem 0;*/
/*}*/

/*.p-slidemenu .p-slidemenu-icon {*/
/*  font-size: 0.875rem;*/
/*}*/

/*.p-slidemenu .p-slidemenu-backward {*/
/*  padding: 0.75rem 1rem;*/
/*  color: #212529;*/
/*}*/

/*.p-tieredmenu {*/
/*  padding: 0.5rem 0;*/
/*  background: #ffffff;*/
/*  color: #212529;*/
/*  border: 1px solid #dee2e6;*/
/*  border-radius: 4px;*/
/*  width: 12.5rem;*/
/*}*/

/*.p-tieredmenu .p-menuitem-link {*/
/*  padding: 0.75rem 1rem;*/
/*  color: #212529;*/
/*  border-radius: 0;*/
/*  transition: box-shadow 0.15s;*/
/*  user-select: none;*/
/*}*/

/*.p-tieredmenu .p-menuitem-link .p-menuitem-text {*/
/*  color: #212529;*/
/*}*/

/*.p-tieredmenu .p-menuitem-link .p-menuitem-icon {*/
/*  color: #212529;*/
/*  margin-right: 0.5rem;*/
/*}*/

/*.p-tieredmenu .p-menuitem-link .p-submenu-icon {*/
/*  color: #212529;*/
/*}*/

/*.p-tieredmenu .p-menuitem-link:not(.p-disabled):hover {*/
/*  background: #e9ecef;*/
/*}*/

/*.p-tieredmenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {*/
/*  color: #212529;*/
/*}*/

/*.p-tieredmenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {*/
/*  color: #212529;*/
/*}*/

/*.p-tieredmenu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {*/
/*  color: #212529;*/
/*}*/

/*.p-tieredmenu .p-menuitem-link:focus {*/
/*  outline: 0 none;*/
/*  outline-offset: 0;*/
/*  box-shadow: inset 0 0 0 0.15rem rgba(38, 143, 255, 0.5);*/
/*}*/

/*.p-tieredmenu.p-tieredmenu-overlay {*/
/*  background: #ffffff;*/
/*  border: 1px solid rgba(0, 0, 0, 0.15);*/
/*  box-shadow: none;*/
/*}*/

/*.p-tieredmenu .p-submenu-list {*/
/*  padding: 0.5rem 0;*/
/*  background: #ffffff;*/
/*  border: 1px solid rgba(0, 0, 0, 0.15);*/
/*  box-shadow: none;*/
/*}*/

/*.p-tieredmenu .p-menuitem {*/
/*  margin: 0;*/
/*}*/

/*.p-tieredmenu .p-menuitem:last-child {*/
/*  margin: 0;*/
/*}*/

/*.p-tieredmenu .p-menuitem.p-menuitem-active > .p-menuitem-link {*/
/*  background: #e9ecef;*/
/*}*/

/*.p-tieredmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text {*/
/*  color: #212529;*/
/*}*/

/*.p-tieredmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon,*/
/*.p-tieredmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon {*/
/*  color: #212529;*/
/*}*/

/*.p-tieredmenu .p-menu-separator {*/
/*  border-top: 1px solid #dee2e6;*/
/*  margin: 0.5rem 0;*/
/*}*/

/*.p-tieredmenu .p-submenu-icon {*/
/*  font-size: 0.875rem;*/
/*}*/

/*.p-inline-message {*/
/*  padding: 0.5rem 0.75rem;*/
/*  margin: 0;*/
/*  border-radius: 4px;*/
/*}*/

/*.p-inline-message.p-inline-message-info {*/
/*  background: #cce5ff;*/
/*  border: solid #b8daff;*/
/*  border-width: 0px;*/
/*  color: #004085;*/
/*}*/

/*.p-inline-message.p-inline-message-info .p-inline-message-icon {*/
/*  color: #004085;*/
/*}*/

/*.p-inline-message.p-inline-message-success {*/
/*  background: #d4edda;*/
/*  border: solid #c3e6cb;*/
/*  border-width: 0px;*/
/*  color: #155724;*/
/*}*/

/*.p-inline-message.p-inline-message-success .p-inline-message-icon {*/
/*  color: #155724;*/
/*}*/

/*.p-inline-message.p-inline-message-warn {*/
/*  background: #fff3cd;*/
/*  border: solid #ffeeba;*/
/*  border-width: 0px;*/
/*  color: #856404;*/
/*}*/

/*.p-inline-message.p-inline-message-warn .p-inline-message-icon {*/
/*  color: #856404;*/
/*}*/

/*.p-inline-message.p-inline-message-error {*/
/*  background: #f8d7da;*/
/*  border: solid #f5c6cb;*/
/*  border-width: 0px;*/
/*  color: #721c24;*/
/*}*/

/*.p-inline-message.p-inline-message-error .p-inline-message-icon {*/
/*  color: #721c24;*/
/*}*/

/*.p-inline-message .p-inline-message-icon {*/
/*  font-size: 1rem;*/
/*  margin-right: 0.5rem;*/
/*}*/

/*.p-inline-message .p-inline-message-text {*/
/*  font-size: 1rem;*/
/*}*/

/*.p-inline-message.p-inline-message-icon-only .p-inline-message-icon {*/
/*  margin-right: 0;*/
/*}*/

/*.p-message {*/
/*  margin: 1rem 0;*/
/*  border-radius: 4px;*/
/*}*/

/*.p-message .p-message-wrapper {*/
/*  padding: 1rem 1.25rem;*/
/*}*/

/*.p-message .p-message-close {*/
/*  width: 2rem;*/
/*  height: 2rem;*/
/*  border-radius: 50%;*/
/*  background: transparent;*/
/*  transition: box-shadow 0.15s;*/
/*}*/

/*.p-message .p-message-close:hover {*/
/*  background: rgba(255, 255, 255, 0.3);*/
/*}*/

/*.p-message .p-message-close:focus {*/
/*  outline: 0 none;*/
/*  outline-offset: 0;*/
/*  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);*/
/*}*/

/*.p-message.p-message-info {*/
/*  background: #cce5ff;*/
/*  border: solid #b8daff;*/
/*  border-width: 1px;*/
/*  color: #004085;*/
/*}*/

/*.p-message.p-message-info .p-message-icon {*/
/*  color: #004085;*/
/*}*/

/*.p-message.p-message-info .p-message-close {*/
/*  color: #004085;*/
/*}*/

/*.p-message.p-message-success {*/
/*  background: #d4edda;*/
/*  border: solid #c3e6cb;*/
/*  border-width: 1px;*/
/*  color: #155724;*/
/*}*/

/*.p-message.p-message-success .p-message-icon {*/
/*  color: #155724;*/
/*}*/

/*.p-message.p-message-success .p-message-close {*/
/*  color: #155724;*/
/*}*/

/*.p-message.p-message-warn {*/
/*  background: #fff3cd;*/
/*  border: solid #ffeeba;*/
/*  border-width: 1px;*/
/*  color: #856404;*/
/*}*/

/*.p-message.p-message-warn .p-message-icon {*/
/*  color: #856404;*/
/*}*/

/*.p-message.p-message-warn .p-message-close {*/
/*  color: #856404;*/
/*}*/

/*.p-message.p-message-error {*/
/*  background: #f8d7da;*/
/*  border: solid #f5c6cb;*/
/*  border-width: 1px;*/
/*  color: #721c24;*/
/*}*/

/*.p-message.p-message-error .p-message-icon {*/
/*  color: #721c24;*/
/*}*/

/*.p-message.p-message-error .p-message-close {*/
/*  color: #721c24;*/
/*}*/

/*.p-message .p-message-text {*/
/*  font-size: 1rem;*/
/*  font-weight: 500;*/
/*}*/

/*.p-message .p-message-icon {*/
/*  font-size: 1.5rem;*/
/*  margin-right: 0.5rem;*/
/*}*/

/*.p-message .p-message-summary {*/
/*  font-weight: 700;*/
/*}*/

/*.p-message .p-message-detail {*/
/*  margin-left: 0.5rem;*/
/*}*/

/*.p-image-mask {*/
/*  --maskbg: rgba(0, 0, 0, 0.9);*/
/*}*/

/*.p-image-preview-indicator {*/
/*  background-color: transparent;*/
/*  color: #f8f9fa;*/
/*  transition: box-shadow 0.15s;*/
/*}*/

/*.p-image-preview-container:hover > .p-image-preview-indicator {*/
/*  background-color: rgba(0, 0, 0, 0.5);*/
/*}*/

/*.p-image-toolbar {*/
/*  padding: 1rem;*/
/*}*/

/*.p-image-action.p-link {*/
/*  color: #f8f9fa;*/
/*  background-color: transparent;*/
/*  width: 3rem;*/
/*  height: 3rem;*/
/*  border-radius: 50%;*/
/*  transition: box-shadow 0.15s;*/
/*  margin-right: 0.5rem;*/
/*}*/

/*.p-image-action.p-link:last-child {*/
/*  margin-right: 0;*/
/*}*/

/*.p-image-action.p-link:hover {*/
/*  color: #f8f9fa;*/
/*  background-color: rgba(255, 255, 255, 0.1);*/
/*}*/

/*.p-image-action.p-link i {*/
/*  font-size: 1.5rem;*/
/*}*/

/*.p-avatar {*/
/*  background-color: #dee2e6;*/
/*  border-radius: 4px;*/
/*}*/

/*.p-avatar.p-avatar-lg {*/
/*  width: 3rem;*/
/*  height: 3rem;*/
/*  font-size: 1.5rem;*/
/*}*/

/*.p-avatar.p-avatar-lg .p-avatar-icon {*/
/*  font-size: 1.5rem;*/
/*}*/

/*.p-avatar.p-avatar-xl {*/
/*  width: 4rem;*/
/*  height: 4rem;*/
/*  font-size: 2rem;*/
/*}*/

/*.p-avatar.p-avatar-xl .p-avatar-icon {*/
/*  font-size: 2rem;*/
/*}*/

/*.p-avatar-group .p-avatar {*/
/*  border: 2px solid #ffffff;*/
/*}*/

/*.p-inplace .p-inplace-display {*/
/*  padding: 0.5rem 0.75rem;*/
/*  border-radius: 4px;*/
/*  transition:*/
/*    background-color 0.15s,*/
/*    border-color 0.15s,*/
/*    box-shadow 0.15s;*/
/*}*/

/*.p-inplace .p-inplace-display:not(.p-disabled):hover {*/
/*  background: #e9ecef;*/
/*  color: #212529;*/
/*}*/

/*.p-inplace .p-inplace-display:focus {*/
/*  outline: 0 none;*/
/*  outline-offset: 0;*/
/*  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);*/
/*}*/
